import React from 'react';
import './newHeader.scss';
import i18next from "i18next";
import log from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import startMessage from '../../../../src/assets/images3/Grupo 1036.svg';
import blueCloud from '../../../../src/assets/images3/Nube.png';
import lang from "../../../assets/images/header/ico99.svg";

interface Props {
    translator?: any;
    return: boolean;
}

class NewHeader extends React.Component <Props> {
    state = {
        currentLanguage: false
    }

    changeLanguage() {
        this.setState({currentLanguage: !this.state.currentLanguage})
        if (this.state.currentLanguage) {
            i18next.changeLanguage("es")
        } else {
            i18next.changeLanguage("en")
        }
    }


    render() {

        return (

            <header>
                <div className="container_header">
                    <div className="items-container">
                        <div className="title-buttons-container">
                            {
                                this.props.return ?
                                    <button className="return"
                                            onClick={() => window.history.back()}>
                                        &lt;
                                    </button>
                                    :
                                    <div></div>
                            }
                            <div className="buttons-header-container">
                                <a id = "pop"href = "https://mypopstoreapp.com/"><label id = "homw"> {this.props.translator("Link-home")}</label></a>
                                <img className="lang-icon" src={lang} alt="" onClick={() => this.changeLanguage()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


        );
    }
}

export default NewHeader;