import React from "react";
import logo from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import {RegistrationFormService} from "../RegistrationForm.service";
import {NotificationType} from "../body/InformationForm";
import {ApiService} from "../../../shared/services/ApiCrud.service";
import {Redirect} from "react-router-dom";
import {AccountRegistrationData} from "../../../shared/interfaces/AccountRegistrationData";
import {environment} from "../../../environment/environment";

interface Props {
    translator?: any;
    service: RegistrationFormService;
}

export class FormStepTwo extends React.Component <Props> {
    public apiService: ApiService = new ApiService();
    state: AccountRegistrationData;
    translator: any;

    constructor(
        props: any
    ) {
        super(props);

        window.scroll(0, 0);

        this.state = this.props.service.getState();
    }

    initTranslator() {
    }

    ///////////////////////////////////// FILL REPUBLICAN STATES COMBO BOX /////////////////////////////////////
    componentDidMount(): void {
        this.apiService.getBasic(`${environment.api.server}${environment.api.context}/basic/federal-entities`).then(response => {
            this.setState({stateList: response, isFetched: false});
            this.props.service.updateState({name: 'stateList', value: response});
            this.props.service.updateState({name: 'isFetched', value: false});
        }).catch(error => {
            console.log('Error from getBasic', error);
        });
    };

    handleChange(event: any) {
        this.props.service.handleChange(event);
        this.setState(this.props.service.getState());
    }

    sendForm() {
        const {errors, ...withoutError} = this.state;

        const result = this.validFormulary(withoutError);

        this.setState({errors: result});

        if (!Object.keys(result).length) {
            let body = {
                name: this.state.businessName,
                commercialName: this.state.commercialBusinessName,
                cellphoneNumber : this.state.cellphoneNumber,
                street: this.state.businessAddress,
                internalNumber: this.state.internalNumber,
                externalNumber: this.state.externalNumber,
                zipCode: this.state.postalCode,
                colonyId: this.state.locationSelected,
            };

            return this.submitForm(body);
        } else {
            console.log('error inside form result', result);
        }
    }

    submitForm(body: any) {
        this.props.service.updateState({name: 'name', value: this.state.businessName});
        this.props.service.updateState({name: 'commercialName', value: this.state.commercialBusinessName});
        this.props.service.updateState({name: 'contactPhone', value: this.state.cellphoneNumber});
        this.props.service.updateState({name: 'street', value: this.state.businessAddress});
        this.props.service.updateState({name: 'internalNumber', value: this.state.internalNumber});
        this.props.service.updateState({name: 'externalNumber', value: this.state.externalNumber});
        this.props.service.updateState({name: 'zipCode', value: this.state.postalCode});
        this.props.service.updateState({name: 'locationSelected', value: this.state.locationSelected});
        this.setState({navigate: true});
    }

    validFormulary(fields: any) {
        const errors: any = {}

        //////////////////////////// EMPTY ///////////////////////////////

        if (!fields.businessName) {
            errors.businessName = this.props.translator('errorBusinessName');
            this.setState({
                cBusinessNameError: this.props.translator('errorEmpty'),
                cBusinessNameM: "error-message", cBusinessName: "field-error"
            })
        }

        if (!fields.cellphoneNumber) {
            errors.cellphoneNumber = this.props.translator('errorPhoneNumber');
            this.setState({ cCellNumError: this.props.translator('errorEmpty'),
                cCellNumM: "error-message", cCellNum : "field-error"});
        }

        if (!fields.commercialBusinessName) {
            errors.commercialBusinessName = this.props.translator('errorCommercialBusinessName');
            this.setState({
                cCommercialNameError: this.props.translator('errorEmpty'),
                cCommercialNameM: "error-message", cCommercialName: "field-error"
            });
        }

        if (!fields.businessAddress) {
            errors.businessAddress = this.props.translator('errorBusinessAddress');
            this.setState({
                cAddressError: this.props.translator('errorEmpty'),
                cAddressM: "error-message", cAddress: "field-error"
            });
        }

        if (!fields.externalNumber) {
            errors.externalNumber = this.props.translator('errorExternalNumber');
            this.setState({
                cExNumError: this.props.translator('errorEmpty'),
                cExNumM: "error-message", cExNum: "field-error-two"
            });
        }

        if (!fields.postalCode) {
            errors.postalCode = this.props.translator('errorZipCode');
            this.setState({
                cZipCodeError: this.props.translator('errorEmpty'),
                cZipCodeM: "error-message", cZipCode: "field-error-two"
            });
        }

        // Implementar Notificación NAG
        if (fields.stateSelected == "Sin seleccionar") {
            errors.stateSelected = this.props.translator('errorState');
            this.showNotification(this.props.translator('errorState'), 'error', 3000);
            this.setState({
                cStateError: this.props.translator('errorState'),
                cStateM: "error-message", cState: "select-information-error right-container"
            });
        }

        // Implementar Notificación NAG
        if (fields.citySelected == "Sin seleccionar") {
            errors.citySelected = this.props.translator('errorCity');
            this.showNotification(this.props.translator('errorCity'), 'error', 3000);
            this.setState({
                cCityError: this.props.translator('errorCity'),
                cCityM: "error-message", cCity: "select-information-error"
            });
        }

        // Implementar Notificación NAG
        if (fields.locationSelected == "Sin seleccionar") {
            errors.locationSelected = this.props.translator('errorLocation');
            this.showNotification(this.props.translator('errorLocation'), 'error', 3000);
            this.setState({
                cLocationError: this.props.translator('errorLocation'),
                cLocationM: "error-message", cLocation: "select-information-error"
            });
        }

        ////////////////////////////// INVALID /////////////////////////////////////////////////////////
        ////////////////////////////// VALIDATE POSTAL CODE //////////////////////////////////////////////
        const pcPattern: RegExp = /^\d{5}$/;
        if (!pcPattern.test(fields.postalCode)) {
            errors.errPostalCode = this.props.translator('invalidZipCode');
        }

        const phonePattern: RegExp = /^\d{10}$/;
        if (!phonePattern.test(fields.cellphoneNumber)) {
            errors.errTelefono = this.props.translator('invalidPhoneNumber');
        }

        /////////////////////////////////////// VALIDATE RAZÓN SOCIAL ////////////////////////////
        const patternRazonSocial: RegExp = /^.{4,100}$/;
        if (!patternRazonSocial.test(fields.razonSocial)) {
            errors.invalidRazonSocial = this.props.translator('invalidBusinessName');
        }
        /////////////////////////////// VALIDATE COMMERCIAL NAME ///////////////////////////
        const pCommercialName: RegExp = /^.{4,100}$/;
        if (!pCommercialName.test(fields.commercialBusinessName)) {
            errors.invalidCommercialName = this.props.translator('invalidCommercialName');
        }

        ///////////////////////////// VALIDATE ADDRESS ///////////////////////////////////
        const pAddress: RegExp = /^.{4,200}$/;
        if (!pAddress.test(fields.businessAddress)) {
            errors.invalidAddress = this.props.translator('invalidAddress');
        }

        /////////////////////////// VALIDATE EXTERNAL NUMBER ////////////////////////////
        const pExNum: RegExp = /^.{1,100}$/;
        if (!pExNum.test(fields.externalNumber)) {
            errors.invalidExtNum = this.props.translator('invalidExtNum');
        }

        //////////////////////////// VALIDATE INTERNAL NUMBER ////////////////////////////
        const pIntNum: RegExp = /^.{1,100}$/;
        if (!pIntNum.test(fields.internalNumber) && fields.internalNumber.length > 0) {
            errors.invalidIntNum = this.props.translator('invalidIntNum');
        }

        const hasErrors = (errorKeys: any[]) => errorKeys.some((key): boolean => errors[key] !== undefined);

        const mandatoryFields: string[] = [
            'businessAddress',
            'externalNumber',
            'businessName',
            'commercialBusinessName',
            'postalCode',
            'stateSelected',
            'contactPhone',
            'citySelected',
            'locationSelected'
        ];

        const invalidFields: string[] = [
            'invalidAddress',
            'invalidIntNum',
            'invalidExtNum',
            'invalidCommercialName',
            'contactPhone',
            'invalidRazonSocial',
        ];

        // Implementar Notificación NAG
        if (hasErrors(mandatoryFields)) {
            this.showNotification(this.props.translator('errorForm'), 'error', 3000);
        } else if (hasErrors(invalidFields)) {
            this.showNotification(this.props.translator('errorForm'), 'error', 3000);
        }

        return errors;
    };

    showNotification = (message: string, type: NotificationType = 'info', duration: number = 3000) => {
        console.log('showNotification', message, type, duration);
        this.setState({showNagNotification: true, nagMessage: message, nagType: type, nagDuration: duration});
        setTimeout(() => {
            this.setState({showNagNotification: false})
        }, duration);
    }

    /////////////////////////////////////// SELECT A STATE ANF FILL CITIES COMBO BOX /////////////////////////////////////////
    selectState(nState: string){
        let stateClass   : string ="cState";
        let stateMessage : string ="cStateM";

        if (nState != null)  {
            this.setState({[stateClass]: "select-information-form right-container"});
            this.setState({[stateMessage]: "no-error-message"});
        }

        const stateId: number = parseInt(nState, 10);
        if (!isNaN(stateId)) {
            this.setState({stateSelected: stateId});
            this.apiService.getBasic(`${environment.api.server}${environment.api.context}/basic/municipalities?federalEntityId=${nState}`).then(response => {
                this.setState({ cityList: response, locationSelected: null, locationList: [] });
            });
        } else {
            this.setState({stateSelected: null, cityList: [], locationSelected: null, locationList: []});
        }
    };

    //////////////////////////////////// SELECT A CITY AND FILL  COLONIES COMBO BOX //////////////////////////////////////////
    selectCity(nCity: string): void {
        let cityClass  : string ="cCity";
        let cityMessage: string ="cCityM";

        if (nCity != null) {
            this.setState({[cityClass]: "select-information-form"});
            this.setState({[cityMessage]: "no-error-message"});
        }

        const cityId: number = parseInt(nCity, 10);
        if (!isNaN(cityId)) {
            this.setState({citySelected: cityId});
            this.apiService.getBasic(`${environment.api.server}${environment.api.context}/basic/colonies?municipalityId=${nCity}`).then(response => {
                this.setState({locationList: response});
            });
        } else {
            this.setState({citySelected: null, locationList: []});
        }
    }

    //////////////////////////////////// SELECT A COLONIE ///////////////////////////////////////////////
    selectLocation(nLocation: string): void {
        let locationClass  : string ="cLocation";
        let locationMessage: string ="cLocationM";

        if (nLocation != null) {
            this.setState({[locationClass]: "select-information-form"});
            this.setState({[locationMessage]: "no-error-message"});
        }

        const locationId: number = parseInt(nLocation, 10);
        if (!isNaN(locationId)) {
            this.setState({locationSelected: locationId});
        } else {
            this.setState({locationSelected: null});
        }
    }

    render() {
        require('./FormStepTwo.scss');
        this.props.service.setTranslator(this.props.translator);

        return (
            <div className="step-main-container">
                <div className="step-logo-container">
                    <img src={logo} alt="logo"/>
                </div>

                <div className="step-content-container">
                    <div className="step-content">
                        <h1 className="step-title">{this.props.translator("stepTwo")}</h1>
                        <p className="step-subtitle">{this.props.translator("stepTwoIndication")}</p>
                    </div>

                    <form className="form"
                          onSubmit={(e) => e.preventDefault()}>
                        <input className={this.state.cBusinessName}
                               onChange={(event) => this.handleChange(event)} type="text"
                               value={this.state.businessName}
                               name="businessName" autoComplete="businessName"
                               placeholder={this.props.translator("razonSocial")}/>
                        <span
                            className={this.state.cBusinessNameM}> {this.state.cBusinessNameError + this.props.translator('minimumCharacters')} </span>

                        <input className={this.state.cCommercialName}
                               onChange={(event) => this.handleChange(event)} type="text"
                                 value={this.state.commercialBusinessName}
                               name="commercialBusinessName" autoComplete="commercialBusinessName"
                               placeholder={this.props.translator("comercialName")}/>
                        <span
                            className={this.state.cCommercialNameM}> {this.state.cCommercialNameError + this.props.translator('minimumCharacters')} </span>

                        <input className={this.state.cCellNum} type="text" name="cellphoneNumber"
                               value={this.state.cellphoneNumber} onChange={(event) => this.handleChange(event)}
                               placeholder={this.props.translator("phoneNumber")} autoComplete="cellphoneNumber"/>
                        <span
                            className={this.state.cCellNumM}> {this.state.cCellNumError + this.props.translator('phoneExample')} </span>
                        <input className={this.state.cAddress}
                               onChange={(event) => this.handleChange(event)} type="text"
                                 value={this.state.businessAddress}
                               name="businessAddress" autoComplete="businessAddress"
                               placeholder={this.props.translator("direction")}/>
                        <span className={this.state.cAddressM}> {this.props.translator('invalidAddress')}</span>

                        <div className="form-row">
                            <div>
                                <input className={this.state.cExNum}
                                       onChange={(event) => this.handleChange(event)} type="text"
                                       value={this.state.externalNumber}
                                       name="externalNumber" autoComplete="externalNumber"
                                       placeholder={this.props.translator("extNum")}/>
                                <span className={this.state.cExNumM}> {this.state.cExNumError} </span>
                            </div>

                            <div>
                                <input className={this.state.cIntNum}
                                       onChange={(event) => this.handleChange(event)} type="text"
                                        value={this.state.internalNumber}
                                       name="internalNumber" autoComplete="internalNumber"
                                       placeholder={this.props.translator("intNum")}/>
                                <span className={this.state.cIntNumM}> {this.state.cIntNumError} </span>
                            </div>
                        </div>

                        <div className="form-row">
                            <div>
                                <input className={this.state.cZipCode}
                                       onChange={(event) => this.handleChange(event)} type="text"
                                        value={this.state.postalCode}
                                       name="postalCode" autoComplete="postalCode"
                                       placeholder={this.props.translator("postalCode")}/>
                                <span className={this.state.cZipCodeM}> {this.state.cZipCodeError} </span>
                            </div>

                            <div className="selector-message">
                                {
                                    <select onChange={(e) => this.selectState(e.target.value)}
                                            className={this.state.cState}>
                                        <option value="selectState">{this.props.translator("state")}</option>
                                        {!this.state.isFetched ?
                                            this.state.stateList.map((state: any) =>
                                                <option key={state.identifier}
                                                        value={state.identifier}>{state.name}</option>) : ' '}
                                    </select>
                                }
                                <span className={this.state.cStateM}> {this.state.cStateError} </span>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="selector-message">
                                {
                                    <select onChange={(e) => this.selectCity(e.target.value)}
                                            className={this.state.cCity}>
                                        <option value="selectCity">{this.props.translator("city")}</option>
                                        {!this.state.isFetched ?
                                            this.state.cityList.map((city: any) =>
                                                <option value={city.identifier}>{city.name} </option>) : ' '}
                                    </select>
                                }
                                <span className={this.state.cCityM}> {this.state.cCityError} </span>
                            </div>

                            <div className="selector-message">
                                {
                                    <select onChange={(e) => this.selectLocation(e.target.value)}
                                            className={this.state.cLocation}>
                                        <option value="selectColonie">{this.props.translator("colonie")}</option>
                                        {!this.state.isFetched ?
                                            this.state.locationList.map((location: any) =>
                                                <option value={location.identifier}>{location.name} </option>) : ' '}
                                    </select>
                                }
                                <span className={this.state.cLocationM}> {this.state.cLocationError} </span>
                            </div>
                        </div>
                    </form>

                    <div className="step-buttons">
                        <button className="back button"
                                onClick={() => window.history.back()}> {this.props.translator("back")} </button>
                        <input type="submit"
                               className="next button"
                               onClick={() => this.sendForm()}
                               value={this.props.translator("continue")}></input>
                        {
                            this.state.navigate ?
                                <Redirect push to="/form/step-three"/> : null
                        }
                    </div>
                </div>

                {
                    (this.state.showNagNotification) ?
                        // Asign class notification and the type of this.state.nagType
                        <div
                            className={`notification ${this.state.nagType}`}>

                            <div className="nag-status-container">
                                <div className={`nag-status ${this.state.nagType}`}>
                                    X
                                </div>
                            </div>

                            {this.state.nagMessage}

                            <span className="close"
                                  onClick={() => this.setState({showNagNotification: false})}
                                  style={{cursor: "pointer"}}>x</span>

                        </div>
                        :
                        <></>
                }
            </div>
        );
    }

}

export default FormStepTwo;