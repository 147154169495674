import React from "react";
import logo from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import {RegistrationFormService} from "../RegistrationForm.service";
import {environment} from "../../../environment/environment";
import {ApiService} from "../../../shared/services/ApiCrud.service";
import {Redirect} from "react-router-dom";
import {NotificationType} from "../body/InformationForm";

interface Props {
    translator?: any;
    service: RegistrationFormService;
}

interface TimerState {
    minutes: number;
    seconds: number;
    isRunning: boolean;
}


export class FormVerifyAccount extends React.Component <Props> {
    public apiService: ApiService = new ApiService();
    state: any = {
        code: '',
        minutes: 0,
        seconds: 10,
        isRunning: false,
        timerInterval: null,

    };
    translator: any;
    // Refs to inputs
    codeInputOne = React.createRef<HTMLInputElement>();
    codeInputTwo = React.createRef<HTMLInputElement>();
    codeInputThree = React.createRef<HTMLInputElement>();
    codeInputFour = React.createRef<HTMLInputElement>();
    codeInputFive = React.createRef<HTMLInputElement>();
    codeInputSix = React.createRef<HTMLInputElement>();
    timerInterval: NodeJS.Timeout | null = null;

    constructor(
        props: any
    ) {
        super(props);

        window.scroll(0, 0);

        // this.state = {...this.props.service.getState(), ...this.state}

        console.log('service.state', this.props.service.getState());
        // console.log('this.state', this.state);
    }

    initTranslator() {
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>, index: number): void {
        const value : string = event.target.value;

        if (value.length > 1) {
            event.target.value = value.slice(0, 1);
        }

        let code = this.state.code || '      ';

        if (value) {
            code = code.substring(0, index) + value + code.substring(index + 1);
        } else {
            code = code.substring(0, index) + " " + code.substring(index + 1);
        }

        code = code.slice(0, 6);

        this.setState({ code });

        if (event.target.value && event.target.nextSibling) {
            (event.target.nextSibling as HTMLInputElement).focus();
        }

    }

    submitForm(): void {
        let body = {
            verificationCode: this.state.code,
            id: this.props.service.getState().id
        };

        this.apiService.postBasic(`${environment.api.server}${environment.api.context}/basic/accounts/verify`, body).then(result => {
            if (result.status == undefined) {
                this.setState({navigate: true});
            } else {
                this.showNotification(this.props.translator('wrongCode'), 'error', 3000);
            }
        });
    }

    resendCode() : void {
        let body = {
            verificationCode: null,
            id: this.props.service.getState().id
        };
        this.apiService.postBasic(`${environment.api.server}${environment.api.context}/basic/accounts/verify/email`, body).then(result => {
            if (result) {
                this.startTimer();
            } else {
                this.showNotification(this.props.translator('sendCodeError'), 'error', 3000);
            }
        });
    }


    showNotification = (message: string, type: NotificationType = 'info', duration: number = 3000) => {
        console.log('showNotification', message, type, duration);
        this.setState({showNagNotification: true, nagMessage: message, nagType: type, nagDuration: duration});
        setTimeout(() => {
            this.setState({showNagNotification: false})
        }, duration);
    }

    startTimer = ()  : void => {
        this.setState({ isRunning: true });

        this.timerInterval = setInterval(()  :void => {
            this.setState((prevState: TimerState) => {
                if (prevState.seconds > 0) {
                    return { seconds: prevState.seconds - 1 };
                } else if (prevState.minutes > 0) {
                    return { minutes: prevState.minutes - 1, seconds: 59 };
                } else {

                    if (this.timerInterval) {
                        clearInterval(this.timerInterval);
                        return { seconds: 10, isRunning: false };
                    }
                }
            });
        }, 1000);
    };

    formatTime = (minutes: number, seconds: number) : string => {
        const minuteStr: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secondStr: string = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minuteStr}:${secondStr}`;
    };

    render() {
        require('./FormVerifyAccount.scss');
        this.props.service.setTranslator(this.props.translator);

        return (
            <div className="step-main-container">
                <div className="step-logo-container">
                    <img src={logo} alt="logo"/>
                </div>

                <div className="step-content-container">
                    <div className="step-content">
                        <h1 className="step-title">{this.props.translator("verifyAccount")}</h1>
                        <p className="step-subtitle">{this.props.translator("verifyAccountIndication")}</p>
                    </div>

                    <form className="verify-form"
                          onSubmit={(e) => e.preventDefault()}>
                        <div className="code-digits">
                            <input
                                type="number"
                                ref={this.codeInputOne}
                                onChange={(e) => this.handleChange(e, 0)}
                                value={this.state.code?.[0] || ''}
                                placeholder="-"
                                className="digit-field"
                            />
                            <input
                                type="number"
                                ref={this.codeInputTwo}
                                onChange={(e) => this.handleChange(e, 1)}
                                value={this.state.code?.[1] || ''}
                                placeholder="-"
                                className="digit-field"
                            />
                            <input
                                type="number"
                                ref={this.codeInputThree}
                                onChange={(e) => this.handleChange(e, 2)}
                                value={this.state.code?.[2] || ''}
                                placeholder="-"
                                className="digit-field"
                            />
                            <input
                                type="number"
                                ref={this.codeInputFour}
                                onChange={(e) => this.handleChange(e, 3)}
                                value={this.state.code?.[3] || ''}
                                placeholder="-"
                                className="digit-field"
                            />
                            <input
                                type="number"
                                ref={this.codeInputFive}
                                onChange={(e) => this.handleChange(e, 4)}
                                value={this.state.code?.[4] || ''}
                                placeholder="-"
                                className="digit-field"
                            />
                            <input
                                type="number"
                                ref={this.codeInputSix}
                                onChange={(e) => this.handleChange(e, 5)}
                                value={this.state.code?.[5] || ''}
                                placeholder="-"
                                className="digit-field"
                            />
                        </div>
                        <div
                            className={`resent-code ${this.state.isRunning ? 'resent-code-disabled' : ''}`}
                            onClick={() : null | void => this.state.isRunning ? null : this.resendCode()}>
                            {this.props.translator("resendCode")}
                        </div>

                        {this.state.isRunning && (
                            <div className="resend-counter">
                                {this.formatTime(this.state.minutes, this.state.seconds)}
                            </div>
                        )}
                    </form>

                    <div className="step-buttons">
                        <button className="back button"
                                onClick={() => window.history.back()}> {this.props.translator("back")} </button>
                        <input type="submit"
                               className="next button"
                               onClick={() => this.submitForm()}
                               value={this.props.translator("continue")}></input>
                        {
                            this.state.navigate ?
                                <Redirect push to="/form/step-two"/> : null
                        }
                    </div>
                </div>

                {
                    (this.state.showNagNotification) ?
                        // Asign class notification and the type of this.state.nagType
                        <div
                            className={`notification ${this.state.nagType}`}>

                            <div className="nag-status-container">
                                <div className={`nag-status ${this.state.nagType}`}>
                                    X
                                </div>
                            </div>

                            {this.state.nagMessage}

                            <span className="close"
                                  onClick={() => this.setState({showNagNotification: false})}
                                  style={{cursor: "pointer"}}>x</span>

                        </div>
                        :
                        <></>
                }
            </div>
        );
    }

}

export default FormVerifyAccount;