import {AccountRegistrationData} from "../../shared/interfaces/AccountRegistrationData";

export class RegistrationFormService {
    private state: AccountRegistrationData = {
        // CAPTCHA
        isARobot: true,
        captchaValue: null,
        captchaToken: "sinToken",

        // TO VALIDATE BUTTONS
        contLogo: 0,
        contOnlinePayment: 0,

        // NORMAL FIELDS VALUE
        id: "",
        principalContact: "",
        email: "",
        cellphoneNumber: "",
        businessName: "",
        commercialBusinessName: "",
        businessAddress: "",
        externalNumber: "",
        internalNumber: "",
        postalCode: "",
        website: "",
        others: "",
        employeeCode: "",

        // FORM BUTTONS
        logo: false,
        wantALogo: false,
        onlinePayment: false,
        termsAndConditions: false,
        privatePolicy: false,

        // SELECTED FROM API
        stateSelected: "Sin seleccionar",
        citySelected: "Sin seleccionar",
        locationSelected: "Sin seleccionar",

        // STATES
        stateList: [],

        // CITIES
        cityList: [],

        // COLONIES
        locationList: [],

        // ERRORS
        errors: {},

        // IS FETCHED?
        isFetched: true,

        // CSS FIELDS (CLASSNAME)
        cPrincipalContact: "field",
        cEmail: "field",
        cCellNum: "field",
        cBusinessName: "field",
        cCommercialName: "field",
        cAddress: "field",
        cState: "select-information-form right-container",
        cLocation: "select-information-form select-information-form",
        cCity: "select-information-form",
        cExNum: "field-two",
        cIntNum: "field-two",
        cPostalCode: "field-two",
        cZipCode: "field-two",
        cWebsite: "field",
        cEmployeeCode: "field",

        // SPAN CSS CLASS
        cCellNumM: "no-error-message",
        cPrincipalContactM: "no-error-message",
        cEmailM: "no-error-message",
        cBusinessNameM: "no-error-message",
        cCommercialNameM: "no-error-message",
        cAddressM: "no-error-message",
        cStateM: "no-error-message",
        cCityM: "no-error-message",
        cLocationM: "no-error-message",
        cExNumM: "no-error-message",
        cIntNumM: "no-error-message",
        cZipCodeM: "no-error-message",
        cWebsiteM: "no-error-message",
        cEmployeeCodeM: "no-error-message",

        //SPAN ERROR MESSAGE INFORMATION
        cCellNumError: " ",
        cPrincipalContactError: " ",
        cEmailError: " ",
        cBusinessNameError: " ",
        cCommercialNameError: " ",
        cAddressError: " ",
        cStateError: " ",
        cCityError: " ",
        cLocationError: " ",
        cExNumError: " ",
        cIntNumError: " ",
        cZipCodeError: " ",
        cWebsiteError: " ",
        cEmployeeCodeError: " ",

        // MODAL WINDOW
        showModal: false,
        showModalError: false,
        modalErrorMessage: '',

        // NAG NOTIFICATION
        showNagNotification: false,
        nagMessage: '',
        nagType: 'error',
        nagDuration: 3000,

        // VALIDATION METHODS
        phoneValidation: false,
        emailValidation: false,
        navigate: false,
        navigateToVerifyAccount: false,
        navigateToInformationForm: false
    };
    private translator: any;

    private static instance: RegistrationFormService;

    private constructor() {
    }

    public static getInstance(): RegistrationFormService {
        if (!RegistrationFormService.instance) {
            RegistrationFormService.instance = new RegistrationFormService();
        }

        return RegistrationFormService.instance;
    }

    public updateState(event: { name: string, value: any }) {
        this.state = {
            ...this.state,
            [event.name]: event.value
        }

        console.log('updateState', this.state);
    }

    public getState(): AccountRegistrationData {
        return this.state;
    }

    public setTranslator(translator: any) {
        this.translator = translator;
    }

    public getTranslator() {
        return this.translator;
    }

    handleChange(event: any): void {
        const expressions = {
            pPrincipalContact: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
            pEmail: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            pCellNum: /^\d{10}$/,
            pSocialReason: /^.{4,100}$/,
            pCommercialName: /^.{4,100}$/,
            pAddress: /^.{4,200}$/,
            pIntNum: /^.{1,100}$/,
            pExNum: /^.{1,100}$/,
            pZipCode: /^\d{5}$/,
            pWebsite: /^.{4,150}$/,
            pEmployeeCode: /^.{6,100}$/,

            user: /^[a-zA-Z0-9_-]{4,16}$/,
            name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
            password: /^.{4,12}$/,
        };

        const target = event.target;
        const value = target.value;
        const name = target.name;

        // Meter el cambio al estado
        this.state = {...this.state, [name]: value};

        // Validar el cambio en el campo
        switch (name) {
            case "principalContact":
                this.validField(expressions.pPrincipalContact, target, 'cPrincipalContact');
                break;
            case "email":
                this.validField(expressions.pEmail, target, 'cEmail');
                break;
            case "cellphoneNumber":
                this.validField(expressions.pCellNum, target, 'cCellNum');
                break;
            case "businessName":
                this.validField(expressions.pSocialReason, target, 'cBusinessName');
                break;
            case "commercialBusinessName":
                this.validField(expressions.pCommercialName, target, 'cCommercialName');
                break;
            case "businessAddress":
                this.validField(expressions.pAddress, target, 'cAddress');
                break;
            case "internalNumber":
                this.validFiledTwo(expressions.pIntNum, target, 'cIntNum');
                break;
            case "externalNumber":
                this.validFiledTwo(expressions.pExNum, target, 'cExNum');
                break;
            case "postalCode":
                this.validFiledTwo(expressions.pZipCode, target, 'cZipCode');
                break;
            case "website":
                this.validField(expressions.pWebsite, target, 'cWebsite');
                break;
            case "employeeCode":
                this.validField(expressions.pEmployeeCode, target, 'cEmployeeCode');
                break;
        }
    };

    validField(expression: any, input: any, changeField: string) {
        //A specific conditional is added here for the website input and employeeCode,
        // as can be seen. The goal is to change its class to 'error'
        // if the minimum number of characters is not met, but to remove the 'error'
        // class if the field is empty again, since the website input and the employeeCode are not mandatory

        let messageError: string = changeField + "M";
        let formatError: string = changeField + "Error";

        if (changeField !== "cWebsite" && changeField !== "cEmployeeCode") {
            if (input.value === "") {
                this.state = {...this.state, [changeField]: "field-error"};
                this.state = {...this.state, [messageError]: "error-message"};
                this.state = {...this.state, [formatError]: this.translator('errorEmpty')};
                return
            }
        }

        if (expression.test(input.value)) {
            this.state = {...this.state, [changeField]: "field"};
            this.state = {...this.state, [messageError]: "no-error-message"};
        } else {
            if ((changeField === "cWebsite" || changeField === "cEmployeeCode") && input.value.length === 0) {
                this.state = {...this.state, [changeField]: "field"};
                this.state = {...this.state, [messageError]: "no-error-message"};
            } else {
                this.state = {...this.state, [changeField]: "field-error"};
                this.state = {...this.state, [messageError]: "error-message"};
                this.state = {...this.state, [formatError]: this.translator('invalidFormat')};
            }
        }
    }

    validFiledTwo(expression: any, input: any, changeField: string) {
        let messageError: string = changeField + "M";
        let formatError: string = changeField + "Error";

        //A specific conditional is added here for the internal number input,
        // as can be seen. The goal is to change its class to 'error' if the condition in valid formulary is not meet
        //  but to remove the 'error' class if the field is empty again, since the internal number input is not mandatory

        if (changeField !== "cIntNum") {
            if (input.value === "") {
                this.state = {...this.state, [changeField]: "field-error-two"};
                this.state = {...this.state, [messageError]: "error-message"};
                this.state = {...this.state, [formatError]: this.translator('errorEmpty')};
                return;
            }
        }

        if (expression.test(input.value)) {
            this.state = {...this.state, [changeField]: "field-two"};
            this.state = {...this.state, [messageError]: "no-error-message"};

        } else {
            if (changeField === "cIntNum" && input.value.length === 0) {
                this.state = {...this.state, [changeField]: "field-two"};
                this.state = {...this.state, [messageError]: "no-error-message"};
            } else {
                this.state = {...this.state, [changeField]: "field-error-two"};
                this.state = {...this.state, [messageError]: "error-message"};
                this.state = {...this.state, [formatError]: this.translator('invalidFormat')};
            }
        }
    }
}