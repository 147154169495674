import React from "react";
import logo from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import {RegistrationFormService} from "../RegistrationForm.service";
import {NotificationType} from "../body/InformationForm";
import {ApiService} from "../../../shared/services/ApiCrud.service";
import {Redirect} from "react-router-dom";
import {AccountRegistrationData} from "../../../shared/interfaces/AccountRegistrationData";
import {environment} from "../../../environment/environment";
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
    translator?: any;
    service: RegistrationFormService;
}

export class FormStepThree extends React.Component <Props> {
    public apiService: ApiService = new ApiService();
    state: AccountRegistrationData;
    translator: any;

    dontWantLogoRadio = React.createRef<HTMLInputElement>();
    form = React.createRef<HTMLFormElement>();

    constructor(
        props: any
    ) {
        super(props);

        this.state = this.props.service.getState();

        // set scroll position to top
        this.form.current?.scrollIntoView({behavior: "smooth", block: "start"});
    }

    initTranslator() {
    }

    changeCaptcha = (): void => {
        this.setState({isARobot: false});
    };

    expiredCaptcha = (): void => {
        this.setState({isARobot: true});
    };

    handleChange(event: any) {
        this.props.service.handleChange(event);
        this.setState(this.props.service.getState());
    }

    sendForm() {
        const {errors, ...withoutError} = this.state;

        const result = this.validFormulary(withoutError);

        console.log('result', result);

        this.setState({errors: result});

        if (!Object.keys(result).length) {
            let body = {
                id: this.state.id,
                name: this.state.businessName,
                commercialName: this.state.commercialBusinessName,
                contactName   : this.state.principalContact,
                contactPhone  : this.state.cellphoneNumber,
                contactEmail  : this.state.email,
                street: this.state.businessAddress,
                internalNumber: this.state.internalNumber,
                externalNumber: this.state.externalNumber,
                zipCode: this.state.postalCode,
                planId: 1,
                colonyId: this.state.locationSelected,
                requiresOnlinePayment: this.state.onlinePayment,
                hasLogo        : this.state.logo,
                requiresNewLogo: this.state.wantALogo,
                comments       : this.state.others,
                urlPlatform    : this.state.website,
                employeeCode   : this.state.employeeCode,
            };

            return this.updateAccount(body);
        } else {
            console.log('error inside form result', result);
        }
    }

    updateAccount (body: any): void {
        const id : string = this.props.service.getState().id;
        this.apiService.put(`${environment.api.server}${environment.api.context}/accounts/landing/${id}`, body).then(
            result => {
                this.setState({navigate: true});
            }, (err) : void => {
                this.showNotification(this.props.translator('accountError'), 'error', 3000);
                console.log(err)
            });
    }

    submitForm(body: any) {
        this.apiService.put(`${environment.api.server}${environment.api.context}/basic/accounts/verify/confirm`, body).then(result => {
            if (result.status == undefined) {
                this.setState({navigate: true});
            } else {
                this.showNotification(
                    result.message,
                    'error', 3000);
            }
        });
    }

    validFormulary(fields: any) {
        const errors: any = {}

        //////////////////////////// EMPTY ///////////////////////////////
        // Implementar notificación NAG
        if (fields.termsAndConditions == false) {
            this.showNotification(this.props.translator('errorTermsAndConditions'), 'error', 3000);
            errors.termsAndConditions = this.props.translator('errorTermsAndConditions');
        }

        // Implementar Notificación NAG
        if (fields.privatePolicy == false) {
            this.showNotification(this.props.translator('errorPrivatePolicy'), 'error', 3000);
            errors.privatePolicy = this.props.translator('errorPrivatePolicy');
        }

        // Implementar notificación NAG
        if (fields.contLogo == 0) {
            this.showNotification(this.props.translator('errorLogo'), 'error', 5000);
            errors.logo = this.props.translator('errorLogo');
        }

        // Implementar Notificación NAG
        if (fields.contOnlinePayment == 0) {
            this.showNotification(this.props.translator('errorPayment'), 'error', 3000);
            errors.onlinePayment = this.props.translator('errorPayment');
        }

        ////////////////////////////////// CAPTCHA //////////////////////////////////////////////////7
        // Implementar Notificación NAG
        if (this.state.isARobot) {
            errors.realizaCaptcha = this.props.translator('errorCaptcha');
            this.showNotification(this.props.translator('errorCaptcha'), 'error', 3000);
        }

        ////////////////////////////// INVALID /////////////////////////////////////////////////////////
        //////////////////////////// VALIDATE  WEBSITE /////////////////////////////////
        const pWebsite: RegExp = /^.{4,150}$/;
        if (!pWebsite.test(fields.website) && fields.website.length > 0) {
            errors.invalidWebsite = this.props.translator('invalidWebsite');
        }

        const hasErrors = (errorKeys: any[]) => errorKeys.some((key): boolean => errors[key] !== undefined);

        const mandatoryFields: string[] = [];

        const invalidFields: string[] = [
            'invalidWebsite',
        ];

        // Implementar Notificación NAG
        if (hasErrors(mandatoryFields)) {
            this.showNotification(this.props.translator('errorForm'), 'error', 3000);
        } else if (hasErrors(invalidFields)) {
            this.showNotification(this.props.translator('errorForm'), 'error', 3000);
        }

        return errors;
    };

    showNotification = (message: string, type: NotificationType = 'info', duration: number = 3000) => {
        console.log('showNotification', message, type, duration);
        this.setState({showNagNotification: true, nagMessage: message, nagType: type, nagDuration: duration});
        setTimeout(() => {
            this.setState({showNagNotification: false})
        }, duration);
    }

    ///////////////////////////////////////////////////// CHANGE CHECKED OF BUTTONS ////////////////////////////
    changeTermsAndConditions(): void {
        if (!this.state.termsAndConditions) {
            this.setState({termsAndConditions: true});
        } else {
            this.setState({termsAndConditions: false});
        }
    };

    changeCheckedLogo(): void {
        if (!this.state.wantALogo) {
            this.setState({wantALogo: true});
        } else {
            this.setState({wantALogo: false});
        }
    };

    changeCheckedPolitic(): void {
        this.setState({privatePolicy: !this.state.privatePolicy});
    };

    render() {
        require('./FormStepThree.scss');
        this.props.service.setTranslator(this.props.translator);

        return (
            <div className="step-main-container">
                <div className="step-logo-container">
                    <img src={logo} alt="logo"/>
                </div>

                <div className="step-content-container">
                    <div className="step-content">
                        <h1 className="step-title">{this.props.translator("stepThree")}</h1>
                        <p className="step-subtitle">{this.props.translator("stepThreeIndication")}</p>
                    </div>

                    <form ref={this.form} className="form"
                          onSubmit={(e) => e.preventDefault()}>
                        <input className={this.state.cWebsite}
                               onChange={(event) => this.handleChange(event)} type="text"
                               name="website" autoComplete="website"
                               placeholder={this.props.translator("website")}/>
                        <span className={this.state.cWebsiteM}> {this.state.cWebsiteError} </span>

                        <input className={this.state.cEmployeeCode}
                               onChange={(event) => this.handleChange(event)} type="text"
                               name="employeeCode" autoComplete="employeeCode"
                               placeholder={this.props.translator("employeeCode")}/>
                        <span className={this.state.cEmployeeCodeM}> {this.state.cEmployeeCodeError} </span>

                        {/*///////////////////////////////// BOTON TIENES LOGO ///////////////////*/}
                        <br/>
                        <div className="question-container">
                            <label className="label-information-Form">{this.props.translator("hasALogo")}</label>
                            <div className="buttons_container">
                                <input type="radio" id="buttonBlack" name="accountLogo" value="si"
                                       onClick={(event) => this.setState({logo: true, contLogo: 1})}/>
                                <label className="eBtnSi" htmlFor="buttonBlack">{this.props.translator("yes")}</label>

                                <input ref={this.dontWantLogoRadio} type="radio" name="accountLogo" value="no" id="buttonWhite"
                                       onClick={(event) => this.setState({logo: false, contLogo: 1})}/>
                                <label className="eBtnNo" htmlFor="buttonWhite"> {this.props.translator("no")}</label>
                            </div>
                        </div>
                        <br/><br/>

                        {/*//////////////////////////////BOTON TE HACEMOS UN LOGO //////////*/}
                        <br/>
                        {!this.state.logo && this.dontWantLogoRadio.current?.checked ?
                            <div className="label-check-container">
                                <label className="label-if-dont">
                                    {this.props.translator("wantALogo1")}
                                </label>
                                <input className="myCheckBoxLogo" type="checkbox" value="valor"
                                       onClick={() => this.changeCheckedLogo()}/>
                            </div> : null
                        }

                        <br/> <br/><br/>

                        {/*////////////////////////////BOTON DESEAS RECIBIR PAGOS EN LINEA///////////7*/}
                        <br/>

                        <div className="question-container">
                            <label
                                className="label-information-Form">{this.props.translator("wantOnlinePayment")}</label>

                            <div className="buttons_container">
                                <input type="radio" name="onlinePayment" id="withPayments" value="si"
                                       onClick={(event) => this.setState({onlinePayment: true, contOnlinePayment: 1})}/>
                                <label className="eBtnSi" htmlFor="withPayments">{this.props.translator("yes")}</label>

                                <input type="radio" name="onlinePayment" id="noPayments" value="no"
                                       onClick={(event) => this.setState({
                                           onlinePayment: false,
                                           contOnlinePayment: 1
                                       })}/>
                                <label className="eBtnSi" htmlFor="noPayments">{this.props.translator("no")}</label>
                            </div>
                        </div>

                        <br/><br/>

                        <label className="label-if-so">{this.props.translator("ifYes")}</label>

                        <br/>
                        <br/>

                        {/*/////////////////////////// BOTON TERMINOS Y CONDICIONES //////////////////7*/}

                        <br/>
                        <div className="question-container">
                            <label className="label-if-so"
                                   onClick={() => this.changeTermsAndConditions()}> {this.props.translator("acceptTermsAndConditions")}<a
                                className="hrefs" href="https://mypopstoreapp.com/terminosycondiciones.html"
                                target="_blank">{this.props.translator("termsAndConditionsW")}</a></label>

                            <input className="myCheckBoxTerms" type="checkbox" checked={this.state.termsAndConditions}
                                   name="terms"
                                   value="valorOtro" onClick={() => this.changeTermsAndConditions()}/>
                        </div>
                        <br/><br/>

                        {/*//////////////////////// BOTON POLITICA DE PRIVACIDAD///////////77*/}
                        <div className="question-container">
                            <label className="label-if-so"
                                   onClick={() => this.changeCheckedPolitic()}> {this.props.translator("acceptPolicy")}
                                <a
                                    className="hrefs" href="https://mypopstoreapp.com/avisodeprivacidad.html"
                                    target="_blank">{this.props.translator("privacyPolicyW")}</a></label>

                            <input className="myCheckBoxTerms" type="checkbox" checked={this.state.privatePolicy}
                                   name="privatePoli" value="unvalor" onClick={() => this.changeCheckedPolitic()}/>
                        </div>

                        {/*///////////////////////////////////////// CAPTCHA ///////////////////////////////////]*/}

                        <br/>

                        <div className="reCaptcha"><ReCAPTCHA sitekey="6LdcS6YdAAAAAD2lW-dy1diju4AP5QYxYpRhW41X"
                                                              onChange={this.changeCaptcha}
                                                              onExpired={this.expiredCaptcha}/>
                        </div>
                    </form>

                    <div className="step-buttons">
                        <button className="back button"
                                onClick={() => window.history.back()}> {this.props.translator("back")} </button>
                        <input type="submit"
                               className="next button"
                               onClick={() => this.sendForm()}
                               value={this.props.translator("continue")}></input>
                        {
                            this.state.navigate ?
                                <Redirect push to="/form/success"/> : null
                        }
                    </div>
                </div>

                {
                    (this.state.showNagNotification) ?
                        // Asign class notification and the type of this.state.nagType
                        <div
                            className={`notification ${this.state.nagType}`}>

                            <div className="nag-status-container">
                                <div className={`nag-status ${this.state.nagType}`}>
                                    X
                                </div>
                            </div>

                            {this.state.nagMessage}

                            <span className="close"
                                  onClick={() => this.setState({showNagNotification: false})}
                                  style={{cursor: "pointer"}}>x</span>

                        </div>
                        :
                        <></>
                }
            </div>
        );
    }

}

export default FormStepThree;