import React from "react";
import logo from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import {Link} from "react-router-dom";

interface Props {
    translator?: any;
}

export class FormStepZero extends React.Component <Props> {

    translator: any;

    constructor(props: any) {
        super(props);

        window.scroll(0, 0);

    }

    initTranslator() {
    }

    render() {
        require('./FormStepZero.scss');
        return (
            <div className="main-container">
                <div className="logo-container">
                    <img src={logo} alt="logo"/>
                </div>

                <div className="content-container">
                    <div className="content">
                        <h1 className="title">{this.props.translator("welcome")}</h1>
                        <p className="subtitle">{this.props.translator("subWelcome")}</p>
                    </div>

                    <Link to="/form/step-one">
                        <button className="button">{this.props.translator("titleForm")}</button>
                    </Link>
                </div>
            </div>
        );
    }

}

export default FormStepZero;