import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import priceMXN from "../assets/images/Body/Membership-screen/Grupo 1035@2x.png";
import priceUSD from "../assets/images/Body/Membership-screen/Grupo 1138@2x.png";


// the translations
// (tip move them in a JSON file and import them)
const resources = {


/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////ESPAÑOL////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////

    es: {
        translation: {
            
            //////////////// imagen descuento//////////////////////////////////
            "priceImage": priceMXN,
            
/////////////////////HEADER  & FOOTER//////////////////////////////
            "LinkTextOne": "¿Qué es?",
            "LinkTextTwo": "Tu app",
            "LinkTextThree": "Planes",
            "LinkTextFour": "Contacto",
            "LinkTextFive": "Términos y Condiciones",
            "LinkTextSix": "Servicios",
            "LinkTextSeven": "Acceso Plataforma",
            "LinkTextEight": "Entrar",
            "LinkTextNine": "Regístrate",
            "LinkEnglish": "English",

/////////////////////////////Section 1 ComponentPhone/////////////////////////////////////
            "SliderOneTitle": "La app perfecta para tu negocio",
            "SliderOneSubTitle": "Vende tus productos en la aplicación de tu negocio.",

            "SliderTwoTitle": "Tu estilo, tu negocio, tu app",
            "SliderTwoSubTitle": "Personaliza la app con los colores e imagen de tu negocio.",

            "SliderThreeTitle": "Recibe pedidos y gestiónalos",
            "SliderThreeSubTitle": "Controla tu negocio desde el panel de control web.",

            "SliderFourTitle": "Siempre conectado con tus clientes",
            "SliderFourSubTitle": "Envíales promociones vía notificación a tu app.",

            "SliderFiveTitle": "Recibe pagos en línea",
            "SliderFiveSubTitle": "Tus clientes podrán hacer pagos con tarjeta y PayPal.",

            "SliderSixTitle": "Diseña tu estrategia de ventas",
            "SliderSixSubTitle": "Ofrece promociones y genera cupones de descuentos.",




/////////////////////////////Section 2 ComponentAbout/////////////////////////////////////
            "AboutTitleOne": "Que tus clientes no te busquen.",
            "AboutTitleTwo": "Con My Pop Store App , ¡tu app va con ellos!",
            "AboutSubTitle": "My Pop Store App es una app de ventas en línea personalizada para ti. Tus clientes la encontrarán en las tiendas Play Store y App Store con el nombre de tu negocio y la podrán descargar para que compren tus productos y servicios.",



/////////////////////////////Section 3 ComponentPersonalized///////////////////////////////
            "PersonalizedTitle": "La app personalizada a tu estilo. Tu logo, tu marca, tu espacio de ventas",

            "PersonalizedSubTitleOne": "Agrega todos los productos y servicios de tu negocio ",
            "PersonalizedTextOne": "Tus clientes podrán crear su carrito de compras y pagar en línea.",

            "PersonalizedSubTitleTwo": "Ofrece promociones y cupones para ofertas exclusivas",
            "PersonalizedTextTwo": "Diseña campañas de ofertas, genera promociones e impleméntalas en tu app como cupones.",

            "PersonalizedSubTitleThree": "Configura la forma en que entregarás sus pedidos ",
            "PersonalizedTextThree": "Tus clientes podrán elegir la entrega en tu negocio o sucursal, envíos locales con alguna agencia de motos o nacionales vía paquetería.",

            "PersonalizedSubTitleFour": "Tu dashboard de ventas, donde quiera que estés",
            "PersonalizedTextFour": "Gestiona tus pedidos y controla tu negocio desde el panel de control web.",

/////////////////////////////Section 4 ComponentManages///////////////////////////////

            "ManagesTitle": "Gestiona todo tu negocio desde el panel de control web",
            "ManagesTextOne": "Tendrás acceso a una plataforma web para el control de tu negocio. Puedes subir tus productos, crear categorías para organizarlos, agregarles fotografía, controlar el stock y muchas cosas más.",
            "ManagesTextTwo": "Administra tus pedidos, cambia el estatus de atención de su pedido para que le llegue notificación a tus clientes, crea cupones para ofrecer promociones y genera reportes de ventas.",
            "ManagesSubTitleThree": "¡Que no se olviden de ti! ",
            "ManagesTextThree": "Mantén tus clientes activos y conectados con tu negocio enviando notificaciones de nuevos productos y promociones.",

/////////////////////////////Section 5 ComponentPayment///////////////////////////////

            "PaymentTitle": "Recibe pagos en línea o a la entrega del pedido",
            "PaymentText": "Configura la forma de pago. Tu aplicación podrá́ recibir pagos en línea con tarjeta de débito/crédito, PayPal, pagos en tiendas de conveniencia, transferencia SPEI o en efectivo al recibir el pedido. ¡Tú decides que opciones ofrecer!.",
            "PaymentSubTitle": "Muchas características más en tu app:",
            "PaymentLi1": "Logo e imagen de tu negocio.",
            "PaymentLi2": "Carrito de compras.",
            "PaymentLi3": "Gestión de direcciones de entrega.",
            "PaymentLi4": "Estatus e historial de pedidos.",
            "PaymentLi5": "Datos de facturación del cliente.",
            "PaymentLi6": "Encuesta de satisfacción.",

/////////////////////////////Section 6 ComponentMembership///////////////////////////////

            "PlansTitle1": "¡Que tu negocio haga ",
            "pop": "POP ",
            "PlansTitle2": "con estas promos!",
            "PlansTitleText": "Aprovecha nuestra promoción de apertura y contrata el plan Básico a un súper precio, en tus primeros tres meses.",
            "PromotionText" : "Promoción",

            //Basic//
            "BasicTitle": "Básico.",
            "BasicPrice": "$349.00",
            "BasicSub": "MXN",

            "BasicLi1": "80 productos.",
            "BasicLi2": "2 fotografías por producto.",
            "BasicLi3": "App Android/IOS.",
            "BasicLi4": "Pagos en efectivo, depósito, transferencia y tarjeta.",
            "BasicLi5": "Carrusel de promociones.", 
            "BasicLi9": "Panel de control web.",
            "BasicLi6": "Reportes.",
            "BasicLi7": "Notificaciones.",
            "BasicLi8": "500 usuarios. *",

            "BasicoMitadTexto1": "Pagos en efectivo, depósito, ",
            "BasicoMitadTexto2": "transferencia y tarjeta.",


            //Enterprising//
            "EnterprisingTitle": "Emprendedor",
            "EnterprisingPrice": "$789.00",
            "EnterprisingSub": "MXN",

            "EnterprisingLi1": "250 productos.",
            "EnterprisingLi2": "3 fotografías por producto.",
            "EnterprisingLi3": "App Android/IOS.",
            "EnterprisingLi4": "Pagos en efectivo, depósito, transferencia, tarjeta y PayPal.",
            "EnterprisingLi5": "Carrusel de promociones.",
            "EnterprisingLi6": "Cupones de descuento.",
            "EnterprisingLi11": "Encuesta de satisfacción.",
            "EnterprisingLi7": "Panel de control web.",
            "EnterprisingLi8": "Reportes.",
            "EnterprisingLi9": "Notificaciones.",
            "EnterprisingLi10": "1000 usuarios. *",

            "EmprendedorMitadTexto1": "Pagos en efectivo, depósito, ",
            "EmprendedorMitadTexto2": "transferencia, tarjeta y PayPal.",
            

            



            //Business//

            "BusinessTitle": "Negocio",
            "BusinessPrice": "$1899.00",
            "BusinessSub": "MXN",


            "BusinessLi1": "500 productos.",
            "BusinessLi2": "5 fotografías por producto.",
            "BusinessLi3": "App Android/IOS.",
            "BusinessLi4": "Pagos en efectivo, depósito, transferencia, tarjeta y PayPal.",
            "BusinessLi5": "Carrusel de promociones.",
            "BusinessLi16": "Promociones al inicio.",
            "BusinessLi6": "Cupones de descuento.",
            "BusinessLi7": "Encuesta de satisfacción.",
            "BusinessLi8": "Panel de control web.",
            "BusinessLi9": "Reportes.",
            "BusinessLi10": "Notificaciones.",
            "BusinessLi11": "2000 usuarios. *",
            "NegocioMitadTexto1": "Pagos en efectivo, depósito, ",
            "NegocioMitadTexto2": "transferencia, tarjeta y PayPal.",
            




            "PlansButton": "Registrarme",
            "PlansInfo1": "*Revisa nuestros Términos y Condiciones. Aplican comisiones adicionales en los pagos en línea.",
            "PlansInfo2": "¿Necesitas más? Contáctanos.",

/////////////////////////////Section 7 ComponentSteps///////////////////////////////


            "StepsTitle": "Pasos para crear tu app",

            "StepsText1": "Solicita el registro de tu negocio dando clic en",
            "StepsButton": "Registrarme.",

            "StepsText2": "Validaremos tus datos y te mandaremos tu acceso al panel de control web donde definirás la imagen de tu app y controlarás tu negocio. Ahí mismo, subirás tus productos y gestionarás tus pedidos.",

            "StepsText3": "Generaremos la aplicación de tu negocio en Android/iOS, la subiremos a las tiendas y ¡listo!. Comparte tu app para que tus clientes la lleven consigo.",



/////////////////////////////Section 8 ComponentContact///////////////////////////////

            "ContactTitle": "Más información",

            "ContactInput1": "Tu nombre*",
            "ContactInput2": "Nombre de tu comercio*",
            "ContactInput3": "Correo electrónico*",
            "ContactInput4": "Teléfono*",
            "ContactInput5": "Mensaje*",

            "ContactButton": "Enviar",



/////////////////////////////Section 9 ComponentFloatingButton///////////////////////////////

            "wa-text": "¿Tienes dudas?",

/////////////////////////////footer-copyright///////////////////////////////

            "FooterTextOne": "Desarrollado por ",
            "FooterTextTwo": "BWL ",
            "FooterTextThree": "en colaboración con MyPopStoreApp.",
            "FooterTextFour": "Consulta nuestro ",
            "FooterTextFour-2": "Aviso de Privacidad.",



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////FFFFAAAAQQQQ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

            // Image offer ////////////////////////////////////////////////////////
            /////////////////////////////HEADER-FAQ///////////////////////////////
            "Link-home": "Inicio",
            "Link-English": "English",

            "Btn-Empy": "Empieza tu app",

            //////////////////////////BodyUp///////////////////////////////
            "TitleBody": "FAQ. ¿Tienes alguna duda? Te ayudamos...",



            //////////////////////////FooterFAQ///////////////////////////////
            "TextQuest": "¿Tienes una pregunta específica?",
            "Textbtn": "Hacer una pregunta",
            "TextCopy": "My Pop Store App COPYRIGHT© 2020. Todos los derechos reservados",

            //////////////////////////AcoordionComponent///////////////////////////////

            "AcoordionInput1": "¿Cómo realizo la contratación de My Pop Store App?",
            "AcoordionLabel1": "En la página web encontrarás una sección llamada pasos para crear tu app, sigue las indicaciones y realiza el proceso de registro.",

            "AcoordionInput2": "¿Cuál es el tiempo de duración del contrato?",
            "AcoordionLabel2": "Tú decides el tiempo que quieres estar con nosotros, el contrato es de tiempo indefinido; puedes cancelar el servicio en el momento que tú quieras. Selecciona el plan que más se adapte a tu negocio y empieza a crear tu aplicación.",

            "AcoordionInput3": "¿Puedo cancelar mi contrato en cualquier momento?",
            "AcoordionLabel3": "Sí, considera que al cancelar tu servicio tu aplicación ya no estará disponible en las tiendas y tendrás un tiempo para regresar con nosotros, pasado este tiempo se borrará tu información y tu aplicación. Revisar términos y condiciones.",

            "AcoordionInput4": "¿Cómo se realiza el pago de mi contrato?",
            "AcoordionLabel4": "Dentro del panel de control web habrá una sección en donde podrás realizar tu pago mensual y ver el historial de pagos.",

            "AcoordionInput5": "¿Necesito realizar un primer pago para confirmar mi contrato?",
            "AcoordionLabel5": "Sí, una vez realizado tu registro deberás realizar tu primer pago para poder tener acceso a la plataforma web. Lo puedes realizar vía transferencia interbancaria, depósito o pago con PayPal. En el proceso de registro se te darán las indicaciones de pago.",

            "AcoordionInput6": "¿Qué significa la cantidad de usuarios en cada plan?",
            "AcoordionLabel6": "Es la cantidad de usuarios incluidos que pueden usar tu aplicación mes a mes. Si excedes esta cantidad, te recomendamos migrar al siguiente plan. Los usuarios adicionales generan comisiones extras.",

            "AcoordionInput7": "¿Por qué los usuarios adicionales generan comisiones extras?",
            "AcoordionLabel7": "La disponibilidad de tu aplicación en la nube es 24/7 para que tus clientes (usuarios) puedan revisar tus productos y hacer las compras requeridas. Sin embargo, queremos asegurarnos de que entre más clientes tengas, mayor disponibilidad de la infraestructura soporte ese incremento de usuarios.",
            "AcoordionLabel7-2": "Esto tendrá un impacto, generando un cargo mínimo de operación adicional por usuario al mes y se indicará como un concepto adicional al pago mensual del plan contratado. Pero recuerda: ",
            "AcoordionLabel7-3": "sólo aplica en el mes en curso, es decir, si en el siguiente mes, no se excede el límite de tu plan, no se generan comisiones extras.",


            "AcoordionInput8": "¿Los usuarios adicionales, estarán limitados?",
            "AcoordionLabel8": "No, podrán usar la app de tu negocio sin ninguna restricción. Adicional, podrás revisar en el panel de control web, la cantidad de usuarios que tienes como invitados o registrados y cuantos de ellos han usado la app en el mes en curso.",
            "AcoordionLabel8-2": "Además, ¡Felicidades! Eso quiere decir que has llegado a más clientes.",

            "AcoordionInput9": "¿Cuáles son los usuarios invitados y cuales los registrados?",
            "AcoordionLabel9": "Los usuarios invitados son aquellos que descargan tu app y navegan en ella para conocer tus productos y servicios. No están obligados a registrarse.",
            "AcoordionLabel9-2": "Los usuarios registrados son aquellos que generan una cuenta en tu app, la cual es requerida para poder realizar una compra.",

            "AcoordionInput10": "¿Qué tiempo tarda en crearse mi app?",
            "AcoordionLabel10": "Una vez realizado el pago de confirmación del contrato:"  ,
            "AcoordionLabel10-2": "Caso 1. Si ya cuentas con el logo de tu negocio, solo necesitas proporcionarlo y la app se crea en una semana aproximadamente.",
            "AcoordionLabel10-3": "Caso 2: Si el logo es solicitado al equipo de diseño de My Pop Store App, la app se crea entre una y dos semanas.",



            "AcoordionInput11": "¿Cuánto tiempo tarda en autorizarse mi app en las tiendas Play Store y App Store?",
            "AcoordionLabel11": "El tiempo de autorización tarda de una a dos semanas. Es de considerar que la autorización depende de las políticas de operación de ambas tiendas. Revisar términos y condiciones.",

            "AcoordionInput12": "¿My Pop Store App consiste solo en una aplicación móvil?",
            "AcoordionLabel12": "No, también se te otorga un acceso a un panel de control y administración web desde el cual podrás gestionar la aplicación de tu negocio.",

            "AcoordionInput13": "¿Cómo se crea mi aplicación móvil?",
            "AcoordionLabel13": "Durante el proceso de registro se te enviará un formulario en donde especificarás los datos de tu negocio, contacto, soporte al cliente, logo, entre otros con los cuales el equipo de desarrollo y centro creativo de My Pop Store App crearán la app de tu negocio y se subirán a las tiendas Play Store y App Store. Este proceso es único y solo se realiza una vez.",

            "AcoordionInput14": "¿Qué pasa si no dispongo de un logo?",
            "AcoordionLabel14": "No te preocupes, como servicio adicional podemos diseñarte un logo y la imagen de tu negocio en la app. Estos servicios podrás requerirlos en el proceso de registro.",

            "AcoordionInput15": "¿Mi app llevará el nombre de mi negocio?",
            "AcoordionLabel15": "Sí, en el proceso de registro de tu negocio se te pedirá incluir el nombre que aparecerá en las tiendas Play Store y App Store. El nombre del negocio deberá respetar las políticas de Play Store y Apple Store. Revisar términos y condiciones.",

            "AcoordionInput16": "¿Cómo se verá mi aplicación en las tiendas Play Store y App Store?",
            "AcoordionLabel16": "Cada tienda requiere de descripciones y diferentes formatos de imágenes de tu app. En el proceso de registro se te pedirán las descripciones. Se subirán imágenes de tu app predeterminadas o podrás solicitar como servicio adicional al equipo de diseño de My Pop Store App imágenes publicitarias específicas.",

            "AcoordionInput17": "¿Quién sube los productos a mi aplicación?",
            "AcoordionLabel17": "Dentro del panel de control web hay una sección que te permite registrar tus productos, editarlos y actualizarlos. Tú controlas lo que quieres que se muestre.",

            "AcoordionInput18": "¿Cómo funciona mi inventario?",
            "AcoordionLabel18": "Cuando un usuario realiza una compra el sistema en automático actualizará tu inventario, restando las piezas adquiridas en el pedido. Esto solo sucede cuando el pedido es confirmado y no cuando se encuentra en el carrito de compra.",

            "AcoordionInput19": "¿Cómo se define el método de pago?",
            "AcoordionLabel19": "Dentro del panel de control web, podrás configurar las formas de pago y elegir cuáles permitirás dentro de tu app móvil.",

            "AcoordionInput20": "¿Qué necesito para recibir pagos en línea?",
            "AcoordionLabel20": "En el proceso de registro, se te enviará la información y documentación requerida para poder registrarte en las pasarelas de pago. El proceso de validación documental y autorización en las pasarelas de pago es ajeno a la operación de My Pop Store App.",

            "AcoordionInput21": "¿Mis ventas me generan un cobro de comisión?",
            "AcoordionLabel21": "Solo los pagos en línea realizados en tu app generan una comisión adicional a tu plan mensual, y son gestionados por la pasarela de pagos. El proceso de validación, identificación, confirmación y autorización de un pago en línea es operado por la pasarela de pagos y es ajeno a la operación de My Pop Store App. La transferencia de pagos, disputas, reclamaciones y bloqueos son operados por la pasarela de pagos y es ajeno a la operación de My Pop Store App. Revistar términos y condiciones.",

            "AcoordionInput22": "¿Cuáles son las formas de envío al realizar un pedido?",
            "AcoordionLabel22": "My Pop Store App te ofrece configurar dentro del panel de control web tres formas de entregar tu pedido y definir cuáles de ellas estarán disponibles para el usuario en cada compra:"  ,
            "AcoordionLabel22-2": "1. Recoger en punto de entrega",
            "AcoordionLabel22-3": "2. Envío a domicilio de forma local",
            "AcoordionLabel22-4": "3. Envío a domicilio vía servicio de paquetería",
            "AcoordionLabel22-5": "NOTA: La gestión entre el negocio y el servicio de entrega local o mediante paquetería, es responsabilidad del mismo negocio.",


            "AcoordionInput23": "¿Se pueden rastrear mis envíos?",
            "AcoordionLabel23": "La gestión entre el negocio y el servicio de entrega local o de paquetería, es ajeno a la operación de MyPopApp; sin embargo, puedes agregar un comentario donde indiques el número de rastreo, la compañía y hasta el link de seguimiento del pedido.",

            "AcoordionInput24": "¿Puedo generar cupones de descuento personalizados?",
            "AcoordionLabel24": "Sí, dentro del panel de control web podrás crear tus cupones personalizados tanto en el nombre como en el monto de descuento y productos aplicables al mismo. Podrás encontrar más información en el panel de control web.",

            "AcoordionInput25": "¿Cómo puedo dar a conocer mi app?",
            "AcoordionLabel25": "Una vez que tu app se encuentra en las tiendas Play Store y App Store se te proporcionarán las ligas de descarga directa las cuales podrás utilizar en tu estrategia de marketing para dar a conocer tu tienda en línea a tus clientes.",


            
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////SERVICES///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
            

            //////////////////////////////////////////////////Body///////////////////////////////////////////

            

            "ServiceTitle": "Servicios. ¿Necesitas un POP extra? Te ayudamos…",
            "ServiceSubTitle": "Lorem Ipsum bla bla bla bla",

            "Title1": "Diseño de Identidad de marca",
            "Paragraph1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",

            "Title2": "Manejo de Redes sociales",
            "Paragraph2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",


            "Title3": "Fotografía de producto",
            "Paragraph3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",

            "Title4": "Otro servicio que aún no sé",
            "Paragraph4": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
                                //////////////////////////////////// Buttons /////////////////////
                                "ServiceBtn1": "Empezar",








            ////////////////////////////////////////////////////////footer//////////////////////////////////////////

            ///////////////////////////////////////////////// FORM  ////////////////////////////////////////////

            "welcome": "Bienvenido a MyPopStore App",
            "subWelcome": "Para poder registrarte en nuestra plataforma, vamos a solicitar información del negocio y datos del contacto principal.",

            "stepOne": "1. Datos de contacto",
            "stepOneIndication": "Ingresa el nombre del contacto principal. Para confirmar tu identidad verifica tu correo electrónico o tu celular",
            "verifyAccount": "Ingrese el código de verificación",
            "resendCode" : "Reenviar código",
            "verifyAccountIndication": "Se te ha enviado un código de 6 dígitos. Esto nos ayuda a mantener su cuenta segura al verificar que realmente eres tú.",
            "stepTwo": "2. Datos del negocio",
            "stepTwoIndication": "Ingresa los datos del negocio para saber más sobre él.",
            "stepThree": "3. Perfil del negocio",
            "stepThreeIndication": "Ingresa los datos del negocio para saber más sobre él.",
            "succesTitle": "Gracias por registrarse con nosotros.",
            "successIndication": "Te enviaremos a tu correo los datos de acceso de tu cuenta y podrás empezar a crear la app de tu negocio.",


            "titleForm": "¿Estás listo para crear tu app?", 
            "subtitleForm" :"Ingresa los datos del contacto principal, con el cual te daremos de alta en nuestra plataforma.", 
            "hasALogo": "¿Tu negocio cuenta con logotipo? *",
            "wantALogo1": "Si elegiste No, ¿Necesitas que diseñemos tu logotipo? Tenemos un centro creativo a tu disposición. ",
            "wantALogo2": "Tenemos un centro creativo a tu disposición.",
            "wantOnlinePayment": "¿Deseas recibir pagos en línea?", 
            "ifYes": "Si elegiste sí, te enviaremos las indicaciones correspondientes", 
            "acceptTermsAndConditions":"He leído y acepto los ", 
            "acceptPolicy":"He leído y acepto la ", 
            "buttonRegister": "Registrar", 

            "principalContact":"Nombre del contacto principal *",
            "yourEmail": "Correo electrónico *",
            "phoneNumber" :"Número celular *", 
            "razonSocial": "Nombre del negocio o razón social *", 
            "comercialName": "Nombre comercial del negocio *", 
            "direction": "Calle o avenida *",
            "extNum":"Número exterior *", 
            "intNum": "Número interior", 
            "city": "Ciudad *", 
            "colonie":  "Colonia *", 
            "postalCode": "Código Postal *", 
            "state": "Estado *", 
            "website": "Página o sitio web del negocio", 
            "employeeCode": "Código de vendedor",
            "others": "Si seleccionaste otros, escríbelo",

            "verify": "Verificar",
            "yes": "Sí", 
            "no": "No",
            "resend": "Reenviar código",
            "continue": "Continuar",
            "back": "Atrás",

            "termsAndConditionsW": "Términos y condiciones", 
            "privacyPolicyW": "Política de privacidad",

            "welcomeModal": "Bienvenido a MyPopStoreApp",
            "thanksRegistration": "Gracias por registrarse con nosotros.",
            "sendMail": " Te enviaremos a tu correo los datos de acceso de tu cuenta y podrás empezar a crear la app de tu negocio.",
            "acceptModal": "Aceptar",
            "titleModal": "Esta cuenta ya pertenece a un negocio",
            "instructions": "Hola, si desea dar de alta otro negocio en su cuenta puede hacerlo desde la plataforma administrativa.",
            "redirectToWeb": "Puede hacer click en el siguiente boton para ir a la plataforma.",
            "goToWeb" : "Ir a la plataforma",

            "modalError": "No es posible registrar el negocio",
            "accountError": "No fue posible crear la cuenta.",
            "wrongCode": "El código es incorrecto.",
            "UserAlreadyExist" : "Este correo ya esta en uso, por favor intenta con uno diferente",
            "BusinessAdminActive" : "Este usuario ya esta registrado como negocio",
            "sendCodeError" : "Ocurrió un error al reenviar el código",

            //// Validations ///
            "errorPrincipalContact": "Escribe un contacto principal",
            "errorEmail": "Escribe tu email",
            "errorPhoneNumber": "Escribe tu número telefónico",
            "errorBusinessName": "Escribe una razón social",
            "errorCommercialBusinessName": "Escribe el nombre comercial de tu negocio",
            "errorBusinessAddress": "Escribe una dirección",
            "errorExternalNumber": "Escribe el número externo",
            "errorInternalNumber": "Escribe el número interno",
            "errorZipCode": "Escribe el código postal",
            "errorWebSite": "Escribe tu sitio web",
            "errorTermsAndConditions": "Debes aceptar los terminos y condiciones",
            "errorPrivatePolicy": "Debes aceptar la política de privacidad",
            "errorLogo": "Selecciona un botón para ¿Tu negocio cuenta con logotipo?",
            "errorPayment": "Elige un botón para el pago en línea",
            "errorState": "Selecciona un estado",
            "errorCity": "Selecciona una ciudad",
            "errorLocation": "Selecciona una colonia",
            "errorCaptcha": "Realiza el captcha",
            "invalidEmail": "Ingresa un email válido",
            "invalidPhoneNumber": "Ingresa un telefono celular válido",
            "invalidZipCode": "Ingresa un código postal válido",
            "invalidPrincipalContact": "Ingresa un contacto principal valido",
            "invalidBusinessName": "Razón social inválida",
            "invalidCommercialName": "Nombre comercial invalido",
            "invalidAddress": "Dirección inválida",
            "invalidExtNum": "Número extrerno inválido",
            "invalidIntNum": "Número interno inválido",
            "invalidWebsite": "Website inválido",
            "errorForm": "Hay errores en el formulario",
            "errorServe": "Algo ha salido mal con el sevidor",
            "errorEmpty": "Este campo no puede estar vacío. ",
            "minimumCharacters": "Necesitas ingresar un mínimo de cuatro letras.",
            "invalidFormat": "El texto no cumple con el formato requerido. ",
            "emailExample": "Ejemplo de correo: ejemplo@hotmail.com",
            "phoneExample": "Ejemplo de número telefónico: 5512345678"
       }
    },





/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////ENGLISH////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////

        en: {
        translation: {
            
/////////////////////HEADER  & FOOTER//////////////////////////////
            "priceImage": priceUSD,
            "LinkTextOne": "How it works?",
            "LinkTextTwo": "Your app",
            "LinkTextThree": "Plans",
            "LinkTextFour": "Contact",
            "LinkTextFive": "Terms & conditions",
            "LinkTextSix": "Services",
            "LinkTextSeven": "Platform Access",
            "LinkTextEight": "Login",
            "LinkTextNine": "Sign up",
            "LinkEnglish": "Español",

/////////////////////////////Section 1 ComponentPhone/////////////////////////////////////
            "SliderOneTitle": "The right app for your business",
            "SliderOneSubTitle": "Sell you products through your buisness app.",
            "ContainerImgOne": "Sell your products through your business app.",

            "SliderTwoTitle": "Your style, your business, your own app",
            "SliderTwoSubTitle": "Customize your app using your corporate brand colors.",

            "SliderThreeTitle": "View your orders and manage them",
            "SliderThreeSubTitle": "Take control on your business using the web admin panel.",

            "SliderFourTitle": "Keep in touch with your customers",
            "SliderFourSubTitle": "Send offers to your customers through in-app notifications.",

            "SliderFiveTitle": "Accept online payments",
            "SliderFiveSubTitle": "Your customers will be able to pay using their cards and PayPal accounts.",

            "SliderSixTitle": "Make your own sales strategy",
            "SliderSixSubTitle": "Share offers and use discount coupons.",




/////////////////////////////Section 2 ComponentAbout/////////////////////////////////////
            "AboutTitleOne": "Don't let your customers look for you.",
            "AboutTitleTwo": "Through My Pop Store App , Your app comes to get them!",
            "AboutSubTitle": "My Pop Store App  is a sales-application focused for your business. Your customers will find it in Play Store and The App Store with your business name and they will be able to download it and buy your products and services.",



/////////////////////////////Section 3 ComponentPersonalized///////////////////////////////
            "PersonalizedTitle": "The app focused to keep your style. Your logo, your brand, your own sales space",

            "PersonalizedSubTitleOne": "Add all your business products and services",
            "PersonalizedTextOne": "Your customers will be able to create their own shopping cart and pay online.",

            "PersonalizedSubTitleTwo": "Give your customers exclusive offers and coupons",
            "PersonalizedTextTwo": "Design campaings and make offers to be used in your app as coupons.",

            "PersonalizedSubTitleThree": "Set up your own delivery service",
            "PersonalizedTextThree": "Your customers will choose between different delivery options, use your preferred delivery service and offer local and national delivery.",

            "PersonalizedSubTitleFour": "Your sales dashboard, anywhere you are",
            "PersonalizedTextFour": "Manage your orders and take control over your business using the admin panel.",

/////////////////////////////Section 4 ComponentManages///////////////////////////////

            "ManagesTitle": "Manage your business using the web admin panel",
            "ManagesTextOne": "You will have  access to web admin panel to keep your business in track. You can upload new products, create your own categories, add a photo, view your stock and so much more!.",
            "ManagesTextTwo": "Manage your orders, change their status, and let your customers know on real time about their orders, create coupons with offers and check your sales report.",
            "ManagesSubTitleThree": "Don't let your customers miss you!",
            "ManagesTextThree": "Keep your customers active and updated about your business by sending notifications with new products and offers.",

/////////////////////////////Section 5 ComponentPayment///////////////////////////////

            "PaymentTitle": "Accept online payments or upon delivery",
            "PaymentText": "Set up the payment method. Your app will accept online payments through debit/credit cards, PayPal, Oxxo pay, SPEI transfer, or cash upon delivery. You choose the payment methods to offer to your customers!.",
            "PaymentSubTitle": "We've got a bunch of additional features for your app:",
            "PaymentLi1": "Logo and corporate brand for your business.",
            "PaymentLi2": "Shopping cart.",
            "PaymentLi3": "Manage delivery addresses.",
            "PaymentLi4": "Orders status and history.",
            "PaymentLi5": "Customers billing information.",
            "PaymentLi6": "Satisfaction survey.",

/////////////////////////////Section 6 ComponentMembership///////////////////////////////

            "PlansTitle1": "Your buisness will make ",
            "pop": "POP",
            "PlansTitle2": " with this promotions",
            "PlansTitleText": "Our plans* to fit your business needs. Service payment is charged monthly.",
            "PromotionText" : "Promotion",

            //Basic//
            "BasicTitle": "Basic",
            "BasicPrice": "$19.00",
            "BasicSub": "DLLS",

            "BasicLi1": "80 products.",
            "BasicLi2": "2 photos per product.",
            "BasicLi3": "Android/iOS App.",
            "BasicLi4": "Accept cash and online payments.",
            "BasicLi5": "Promotions carousel.",
            "BasicLi9": "Web control panel",
            "BasicLi6": "Reports.",
            "BasicLi7": "Notifications.",
            "BasicLi8": "500 users. *",


           /* Accept cash, deposit, transfer, pay card and PayPal.*/

            "NegocioMitadTexto1": "Accept cash, deposit, transfer,",
            "NegocioMitadTexto2": " pay card and PayPal.",

            "EmprendedorMitadTexto1": "Accept cash, deposit, transfer,",
            "EmprendedorMitadTexto2": " pay card and PayPal.",

            "BasicoMitadTexto1": "Accept cash, deposit, transfer ",
            "BasicoMitadTexto2": "and pay card.",

            
            
            



            //Enterprising//
            "EnterprisingTitle": "Entrepreneur",
            "EnterprisingPrice": "$39.00",
            "EnterprisingSub": "DLLS",

            "EnterprisingLi1": "250 products.",
            "EnterprisingLi2": "3 photo per product.",
            "EnterprisingLi3": "Android/iOS App.",
            "EnterprisingLi4": "Accept cash and online payments.",
            "EnterprisingLi5": "Promotions carousel.",
            "EnterprisingLi6": "Discount coupons.",
            "EnterprisingLi11": "Satisfaction survey.",
            "EnterprisingLi7": "Web control panel.",
            "EnterprisingLi8": "Reports.",
            "EnterprisingLi9": "Notifications.",
            "EnterprisingLi10": "1000 users. *",
            



            //Business//

            "BusinessTitle": "Business",
            "BusinessPrice": "$95.00",
            "BusinessSub": "DLLS",

            "BusinessLi1": "500 products.",
            "BusinessLi2": "5 photos per product.",
            "BusinessLi3": "Android/iOS App.",
            "BusinessLi4": "Accept cash and online payments.",
            "BusinessLi5": "Promotions carousel.",
            "BusinessLi6": "Discount coupons.",
            "BusinessLi16": "Initial promotions.",
            "BusinessLi7": "Satisfaction survey.",
            "BusinessLi8": "Control web panel.",
            "BusinessLi9": "Reports.",
            "BusinessLi10": "Notifications.",
            "BusinessLi11": "2000 users. *",
            "BusinessLi12": "5 photos",

            
          
            






            "PlansButton": "Register",
            //"PlansInfo1": "Additional fees may apply to online payments.",
            "PlansInfo1": "",
            "PlansInfo2": "* Review terms and conditions. More information? Contact us.",

/////////////////////////////Section 7 ComponentSteps///////////////////////////////


            "StepsTitle": "Steps to create your own app",

            "StepsText1": "Make a business registration request by clicking in",
            "StepsButton": "Register.",

            "StepsText2": "We will check your provided information and we will send you a new access to the admin panel where you will set up your business brand and app customization. There you will be able to upload your products and manage your orders.",

            "StepsText3": "We will make your business app for Android/iOS and we will upload it to the app stores and done!. Share your app to let your customers get your products!.",



/////////////////////////////Section 8 ComponentContact///////////////////////////////

            "ContactTitle": "More information",

            "ContactInput1": "Your name*",
            "ContactInput2": "Your business name*",
            "ContactInput3": "E-mail*",
            "ContactInput4": "Phone*",
            "ContactInput5": "Message*",

            "ContactButton": "Send",



/////////////////////////////Section 9 ComponentFloatingButton///////////////////////////////

            "wa-text": "Contact us",

/////////////////////////////footer-copyright///////////////////////////////

            "FooterTextOne": "Developed by ",
            "FooterTextTwo": "BWL ",
            "FooterTextThree": "in collaboration with MyPopStoreApp.",
            "FooterTextFour": "Check our ",
            "FooterTextFour-2": "privacy policy.",



////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////FFFFAAAAQQQQ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

            /////////////////////////////HEADER-FAQ///////////////////////////////
            "Link-home": "Home",
            "Link-English": "Español",

            "Btn-Empy": "Start your app",

            //////////////////////////BodyUp///////////////////////////////
            "TitleBody": "FAQ. Do you have any doubt? we can help you...",



            //////////////////////////FooterFAQ///////////////////////////////
            "TextQuest": "Do you have any specific question?",
            "Textbtn": "Ask a question",
            "TextCopy": "My business app COPYRIGHT© 2020. All rights reserved",

            //////////////////////////AcoordionComponent///////////////////////////////


            "AcoordionInput1": "How do I contract My Pop Store App’s service?",
            "AcoordionLabel1": "In our web site you will find a section called Steps to create your own app, follow the instructions and complete the registration process.",

            "AcoordionInput2": "What is the duration of the contract?",
            "AcoordionLabel2": "You choose the time you want to be with us, the contract has an undefined duration, meaning you can cancel the service the moment you want. Select the plan that best suits your business and start creating your own business app.",

            "AcoordionInput3": "Can I cancel the service anytime?",
            "AcoordionLabel3": "Yes, but please consider that the moment you cancel the service your app will not be available for download in the Google and Apple Stores, you will be given a certain time to come back, after said time all your information and app will be deleted. Please refer to terms & conditions.",

            "AcoordionInput4": "How is the payment of the service made?",
            "AcoordionLabel4": "In the web admin panel you will find a section where you can make your monthly payment and see your payments history.",

            "AcoordionInput5": "Do I need to make a first payment to confirm my contract?",
            "AcoordionLabel5": "Yes, once you have registered you must make a first payment to have access to the web platform. You can make it either bank transfer, deposit or via PayPal. In the registration process you will receive the payment instructions.",

            "AcoordionInput6": "What does the number of users mean on each plan?",
            "AcoordionLabel6": "It is the number of users that can use your app monthly. If you exceed this number we recommend you to change to the next plan. The number of exceeding users generate an extra fee.",

            "AcoordionInput7": "Why does the exceeding users generate an extra fee?",
            "AcoordionLabel7": "The availability of your application in the cloud is 24/7 so that your customers (users) can check your product and make their purchases anytime. However, we want to make sure that the more clients you have, the greater the availability of the infrastructure we offer, supports this increase in users.",
            "AcoordionLabel7-2": "This will have an impact, generating a minimum additional operation charge per user per month and will be indicated as an additional concept to the monthly payment of the contracted plan. But remember: ",
            "AcoordionLabel7-3": "it only applies in the current month, that is, if in the following month, the limit of your plan is not exceeded, no extra commissions are generated.",


            "AcoordionInput8": "The additional users will be limited?",
            "AcoordionLabel8": "No, they will be able to use your business app without any restrictions. Additionally, you can check in the web admin panel, the number of users you have as guests or registered and how many of them have used the app in the current month.",
            "AcoordionLabel8-2": "",

            "AcoordionInput9": "Which are the invited users and which are the registered ones?",
            "AcoordionLabel9": "Guest users are those who download your app and browse it to find out about your products and services. They are not required to register.",
            "AcoordionLabel9-2": "Registered users are those who create an account in your app, which is required to make a purchase.  ",

            "AcoordionInput10": "How long does it take for my app to be created?",
            "AcoordionLabel10": "Once you have made the confirmation payment:"  ,
            "AcoordionLabel10-2": "Case 1. If the logo is provided the app will take one week to be created.",
            "AcoordionLabel10-3": "Case 2. If the logo is solicited to My Pop Store App’s design team, the app takes between one to two weeks to be created.",



            "AcoordionInput11": "How long does it take for my app to be authorized by Google and Apple’s stores?",
            "AcoordionLabel11": "The authorization time takes one to two weeks. You must consider that the authorization depends on the operating policies of both stores. Refer to terms & conditions.",

            "AcoordionInput12": "Does My Pop Store App consist of a mobile app only?",
            "AcoordionLabel12": "No, you also get access to a web admin panel, from where you will be able to manage your business app.",

            "AcoordionInput13": "How is my mobile app created?",
            "AcoordionLabel13": "During the registration process you will receive a form where you must specify your business, contact and support data as well as your business logo, etc. with which the development team and creative center of My Pop Store App will create your very own business app and will upload it to Play Store and App Store. This is a one-time only process.",

            "AcoordionInput14": "What if I don’t have a logo?",
            "AcoordionLabel14": "Don’t worry about it, as an additional service we can design a logo and your business image in the app. You can require these services during the registration process.",

            "AcoordionInput15": "Will my mobile app have my business name?",
            "AcoordionLabel15": "Yes, in the registration process you will be asked to include the name that will appear in Google’s Play Store and Apple’s App Store. Your business however must respect the policies of said stores. Please refer to terms & conditions.",

            "AcoordionInput16": "How will me app look in Google’s Play Store and Apple’s App Store?",
            "AcoordionLabel16": "Each of the stores requires the description and different image formats of your app. In the registration process you will be asked said descriptions. Default images of your app will be uploaded or as an additional service of My Pop Store App design team you can request specific advertising images.",

            "AcoordionInput17": "Who uploads my products in my app?",
            "AcoordionLabel17": "In the web admin panel you will find a section that allows you to register, edit and update your products. You control whatever you want to be shown.",

            "AcoordionInput18": "How does my inventory work?",
            "AcoordionLabel18": "When a user buys a product the system will automatically update your inventory, deducting the acquired pieces in said sale. This only applies when the sale is completed and not when the product is still in the shopping cart.",

            "AcoordionInput19": "How is my payment method defined?",
            "AcoordionLabel19": "In the web admin panel you will be able to set the payment method and choose which ones will be available in your app.",

            "AcoordionInput20": "What do I need to receive online payments?",
            "AcoordionLabel20": "During the registration process you will receive the information and documentation required to be registered in the payment gateways. The documental validation process and authorization in the payment gateways is not managed by My Pop Store App.",

            "AcoordionInput21": "Does my sales generate a commission charge?",
            "AcoordionLabel21": "Only online payments made in your app generate an additional charge to your monthly plan, and are managed by the payments gateway. The validation, identification, confirmation and authorization process of an online payment is operated by the payments gateway and it is foreign to My Pop Store App’s operation. Please refer to Terms & Conditions.",

            "AcoordionInput22": "Which are the delivery options when a sale has been made?",
            "AcoordionLabel22": "My Pop Store App offers you to set in the web admin panel, three ways to deliver your product and define which will be available for use in each sale."  ,
            "AcoordionLabel22-2": "1. Pick up at delivery point",
            "AcoordionLabel22-3": "2. Home delivery through a local service",
            "AcoordionLabel22-4": "3. Home delivery through a parcel service",
            "AcoordionLabel22-5": "NOTE: The management between the business and the chosen delivery service is responsibility of said business.",


            "AcoordionInput23": "Can my deliveries be tracked?",
            "AcoordionLabel23": "The management between the business and the delivery service is foreign to the operation of My Pop Store App; nevertheless, you can add a notification message where you indicate the tracking number, delivery company and even the tracking link.",

            "AcoordionInput24": "Can I generate personalized discount coupons?",
            "AcoordionLabel24": "Yes, in the web admin panel you will be able to create personalized coupons both in the name and discount amount as well as choose the applicable products to it. You can find more information about it in the web control panel.",

            "AcoordionInput25": "How can I make my app known?",
            "AcoordionLabel25": "Once your app is available in the Play Store and App Store we will provide you a direct download link to each store, which you will be able to use according to your marketing strategy so you can make your online store known to your clients.",

            ///////////////////////////////////////////// FORM//////////////////////////////////////////

            "welcome": "Welcome to MyPopStore App",
            "subWelcome": "Fill with the information of the principal contact, with which you will be registered.",

            "stepOne": "1. Contact information",
            "stepOneIndication": "Enter the name of the principal contact. To confirm your identity verify your email or your cell phone",
            "verifyAccount": "Enter the verification code",
            "resendCode": "Resend code",
            "verifyAccountIndication": "A 6-digit code has been sent to you. This helps us keep your account secure by verifying that it is really you.",
            "stepTwo": "2. Business information",
            "stepTwoIndication": "Enter the business information to know more about it.",
            "succesTitle": "Thanks for registering with us.",
            "successIndication": "We will send you the login credentials to your email, and you can start creating the business app.",

            "titleForm": "Are you ready to create your app?",
            "subtitleForm" :"Provide us with the principal contact information, with which you will get registered in our platform",
            "hasALogo": "Does you buisness has a logo? *",
            "wantALogo1": "If you choosed no, Do you want us to design a your logo?",
            "wantALogo2": " we have an entire creative center for you.",
            "wantOnlinePayment": "Do you want online payment?",
            "ifYes": "If so, we will send you the indications", 
            "acceptTermsAndConditions":"I read and accept the ", 
            "acceptPolicy":"I read and accept the ", 
            "buttonRegister": "Sing up",

            "principalContact":"Name of the principal contact *",
            "yourEmail": "Email *",
            "phoneNumber" :"Cellphone number *",
            "razonSocial": "Business Name, social reason *",
            "comercialName": "Commercial name *", 
            "direction": "Street or avenue *",
            "extNum":"External number *", 
            "intNum": "Internal Number", 

            "city": "Select a city", 
            "colonie":  "Select a location",
            "state": "Select a state", 

            "postalCode": "Zip code *", 
            


            "website": "Website", 
            "employeeCode": "Seller code",
            "others": "If you selected others, write it here",

            "verify": "Verify",
            "yes": "Yes", 
            "no": "No",
            "resend": "Resend code",
            "continue": "Continue",
            "back": "Back",

            "termsAndConditionsW": "Terms and conditions", 
            "privacyPolicyW": "Privacy policy",

            "welcomeModal": "Welcome to MyPopStore App",
            "thanksRegistration": "Thanks you for registering with us.",
            "sendMail": " We will send you the login credentials to your email, and you can start creating your business app.",
            "acceptModal": "Accept",
            "titleModal": "This account already belongs to a business",
            "instructions": "Hello user, if you wish to register another business to your account, you can do so from the web platform",
            "redirectToWeb": "You can click the following button to go to the platform.",
            "goToWeb":"go to platform",

            "modalError": "It is not possible to register the business",
            "accountError": "It was not possible to create the account.",
            "UserAlreadyExist" : "This email is already in use, please try a different one",
            "BusinessAdminActive" : "This business admin is already active",
            "sendCodeError": "An error occurred while resending the code",
            "wrongCode": "The code is not correct.",

            //// Validations ///
            "errorPrincipalContact": "Write a principal contact",
            "errorEmail": "Write your email",
            "errorPhoneNumber": "Write your phone number",
            "errorBusinessName": "Write your business name",
            "errorCommercialBusinessName": "Write your commercial business name",
            "errorBusinessAddress": "Write your address",
            "errorExternalNumber": "Write your external number",
            "errorInternalNumber": "Write your external number",
            "errorZipCode": "Write the zip code",
            "errorWebSite": "Write your website",
            "errorTermsAndConditions": "You must accept the terms and conditions ",
            "errorPrivatePolicy": "You must accept the privacy policy",
            "errorLogo": "Choose an option for Does your business have a logotype?",
            "errorPayment": "Choose an option for online payment",
            "errorState": "Select a state",
            "errorCity": "Select a city",
            "errorLocation": "Select a location",
            "errorCaptcha": "Complete the captcha",
            "invalidEmail": "Enter a valid email",
            "invalidPhoneNumber": "Enter a valid cell phone number",
            "invalidZipCode": "Enter a valid zip code",
            "invalidPrincipalContact": "Enter a valid principal contact",
            "invalidBusinessName": "Enter a valid business name",
            "invalidCommercialName": "Enter a valid commercial name",
            "invalidAddress": "Enter a valid address",
            "invalidExtNum": "Enter a valid external number",
            "invalidIntNum": "Enter a valid internal number",
            "invalidWebsite": "Enter a valid website",
            "errorForm": "There are errors in the form",
            "errorServe": "Something has gone wrong with the server",
            "errorEmpty" : "This field can't be empty.",
            "minimumCharacters": "You need to enter a minimum of four letters",
            "invalidFormat": "The text does not meet the required format.",
            "emailExample" : "Email example: ejemplo@hotmail.com",
            "phoneExample" : "Phone number example: 5512345678",
        }
    },







};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "es",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    }).then();

export default i18n;
