import React from "react";
import logo from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import {RegistrationFormService} from "../RegistrationForm.service";
import {NotificationType} from "../body/InformationForm";
import {ApiService} from "../../../shared/services/ApiCrud.service";
import {Redirect} from "react-router-dom";
import {environment} from "../../../environment/environment";
import shoppingCart from "../../../assets/images3/body/Trazado 9617@2x.png";
import {AccountRegistrationData} from "../../../shared/interfaces/AccountRegistrationData";

interface Props {
    translator?: any;
    service: RegistrationFormService;
}

export class FormStepOne extends React.Component <Props> {
    public apiService: ApiService = new ApiService();
    state: AccountRegistrationData;
    translator: any;

    constructor(
        props: any
    ) {
        super(props);

        window.scroll(0, 0);

        const initialState : AccountRegistrationData = this.props.service.getState();
        this.state = {
            ...initialState,
            showModal: false
        };
    }

    initTranslator() {
    }

    handleChange(event: any) {
        this.props.service.handleChange(event);
        this.setState(this.props.service.getState());
    }

    verifyForm() {
        const {errors, ...withoutError} = this.state;

        const result = this.validFormulary(withoutError);

        this.setState({errors: result});

        if (!Object.keys(result).length) {
            let packageData = {
                contactName: this.state.principalContact,
                contactEmail: this.state.email,
            };

            let body = {
                contactName: this.state.principalContact,
                contactEmail: this.state.email,
                planId: 1
            };

            return this.submitForm(body);
        } else {
            console.log('error inside form result', result);
        }
    }

    submitForm(body: any) : void {
        this.apiService.postBasic(`${environment.api.server}${environment.api.context}/basic/accounts/preRegistration`,body).then(result => {
            if (result.message) {
               if (result.message === "USER ALREADY EXISTS") {
                   this.showNotification(this.props.translator('UserAlreadyExist'), 'warning', 3000);
               }
               if (result.message === "BUSINESS ADMIN ACTIVE") {
                   this.setState({showModal: true});
               }
            } else if (!result.verifiedAccount) {
                this.setState({navigateToVerifyAccount: true});
                this.props.service.updateState({name: 'id', value: result.identifier});

            } else if (result.verifiedAccount) {
                if (result.commercialName == null || result.commercialName === "") {
                    this.setState({navigateToInformationForm: true});
                    this.props.service.updateState({name: 'id', value: result.identifier});

                } else {
                    this.setState({showModal: true});
                    this.props.service.updateState({name: 'id', value: result.identifier});
                }
            }

            else {
                this.showNotification(this.props.translator('accountError'), 'error', 3000);
            }
        });
    }

    validFormulary(fields: any) {
        const errors: any = {}

        //////////////////////////// EMPTY //////////////////////////////7
        if (!fields.principalContact) {
            errors.principalContact = this.props.translator('errorPrincipalContact');
            this.setState({
                cPrincipalContactError: this.props.translator('errorEmpty'),
                cPrincipalContactM: "error-message", cPrincipalContact: "field-error"
            });
        }

        if (!fields.email) {
            errors.email = this.props.translator('errorEmail');
            this.setState({
                cEmailError: this.props.translator('errorEmpty'),
                cEmailM: "error-message", cEmail: "field-error"
            });
        }

        /*if (!fields.cellphoneNumber) {
            errors.cellphoneNumber = this.props.translator('errorPhoneNumber');
            this.setState({
                cCellNumError: this.props.translator('errorEmpty'),
                cCellNumM: "error-message", cCellNum: "field-error"
            });
        }*/

        ////////////////////////////// INVALID /////////////////////////////////////////////////////////
        ///////////////////////////////// VALIDATE EMAIL /////////////////////////////////////////
        const patternEmail: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!patternEmail.test(fields.email)) {
            errors.emailInvalid = this.props.translator('invalidEmail');
        }

        ////////////////////////////// VALIDATE PHONE NUMBER ///////////////////////////////
        /*const phonePattern: RegExp = /^\+\d{1,2}\d{10}$/;
        if (!phonePattern.test(fields.cellphoneNumber)) {
            errors.errTelefono = this.props.translator('invalidPhoneNumber');
        }*/

        ///////////////////////////  VALIDATE PRINCIPAL CONTACT ///////////////////////////////
        const patternPrincipalContact: RegExp = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
        if (!patternPrincipalContact.test(fields.principalContact)) {
            errors.invalidContact = this.props.translator('invalidPrincipalContact');
        }

        const hasErrors = (errorKeys: any[]) => errorKeys.some((key): boolean => errors[key] !== undefined);

        const mandatoryFields: string[] = [
            'principalContact',
            'email'
        ];

        const invalidFields: string[] = [
            'invalidContact',
        ];

        // Implementar Notificación NAG
        if (hasErrors(mandatoryFields)) {
            this.showNotification(this.props.translator('errorForm'), 'error', 3000);
        } else if (hasErrors(invalidFields)) {
            this.showNotification(this.props.translator('errorForm'), 'error', 3000);
        }

        return errors;
    };

    showNotification = (message: string, type: NotificationType = 'info', duration: number = 3000) => {
        console.log('showNotification', message, type, duration);
        this.setState({showNagNotification: true, nagMessage: message, nagType: type, nagDuration: duration});
        setTimeout(() => {
            this.setState({showNagNotification: false})
        }, duration);
    }

    verifyAccount(type: string) : void {
        if (type === 'phone') {
            this.verifyForm();
        }
    }

    rechargerPaging(): void {
        this.setState({showModal: false});
        window.location.reload();
    };

    goToPlatform() : void {
        window.location.href = "https://admin.mypopstoreapp.com/login";
    }

    render() {
        require('./FormStepOne.scss');
        this.props.service.setTranslator(this.props.translator);

        return (
            <div className="step-main-container">
                <div className="step-logo-container">
                    <img src={logo} alt="logo"/>
                </div>

                <div className="step-content-container">
                    <div className="step-content">
                        <h1 className="step-title">{this.props.translator("stepOne")}</h1>
                        <p className="step-subtitle">{this.props.translator("stepOneIndication")}</p>
                    </div>

                    <form className="stepone-form"
                          onSubmit={(e) => e.preventDefault()}>
                        <input className={this.state.cPrincipalContact}
                               onChange={(event) => this.handleChange(event)} type="text"
                               value={this.state.principalContact}
                               name="principalContact" autoComplete="principalContact"
                               placeholder={this.props.translator("principalContact")}/>
                        <span
                            className={this.state.cPrincipalContactM}> {this.state.cPrincipalContactError} </span>

                        <div className="verifying-field-group">
                            <input className={this.state.cEmail + ' verifying'}
                                   onChange={(event) => this.handleChange(event)} type="text"
                                   value={this.state.email}
                                   name="email" autoComplete="email"
                                   placeholder={this.props.translator("yourEmail")}/>
                            <input className="verifying-button"
                                   type="submit"
                                   onClick={() => {
                                       this.verifyAccount('phone');
                                   }}
                                   value={this.props.translator("verify")}>
                            </input>
                            {
                                this.state.navigateToInformationForm ?
                                    // TODO: Redirect to /form/verify-account/:id when the verification sms and email services are correctly implemented
                                <Redirect push to={'/form/step-two' + this.state.id}></Redirect> : null
                            }
                            {
                                this.state.navigateToVerifyAccount ?
                                <Redirect push to={'/form/verify-account/' + this.state.id}></Redirect> : null
                            }
                        </div>
                        <span className={this.state.cEmailM}> {this.state.cEmailError} </span>
                    </form>

                    <div className="step-buttons">
                        <button className="back button"
                                onClick={() => window.history.back()}> {this.props.translator("back")} </button>

                        <div></div>
                    </div>
                </div>

                {
                    (this.state.showModal)?
                        <div className = "first-container">
                            <div className = "modal-container">
                                <div className = "all-container">
                                    <div className = "btn-cancel-container">
                                        <img id = "shopping-cart"src = {shoppingCart} />
                                        <button className = "cancel" onClick={(event)=>this.setState({showModal: false})}>X</button>
                                    </div>

                                    <div className = "indications-container">
                                        <p className = "title-indications"> {this.props.translator("titleModal")}</p>
                                        <p className = "p1"> {this.props.translator("instructions")} </p>
                                        <p className = "p2"> {this.props.translator("redirectToWeb")}</p>
                                    </div>

                                    <div className = "button-container">
                                        <button className = "button-close-modal" onClick={(event)=>this.goToPlatform()}>{this.props.translator("goToWeb")}</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
                {
                    (this.state.showNagNotification) ?
                        // Asign class notification and the type of this.state.nagType
                        <div
                            className={`notification ${this.state.nagType}`}>

                            <div className="nag-status-container">
                                <div className={`nag-status ${this.state.nagType}`}>
                                    X
                                </div>
                            </div>

                            {this.state.nagMessage}

                            <span className="close"
                                  onClick={() => this.setState({showNagNotification: false})}
                                  style={{cursor: "pointer"}}>x</span>

                        </div>
                        :
                        <></>
                }
            </div>
        );
    }

}

export default FormStepOne;