import React from 'react';
import './header.scss';
import log from '../../../assets/images/header/Grupo 12@2x.png'
import i18next from "i18next";
import { Link } from "react-router-dom";
import lang from "../../../../src/assets/images/header/ico99.svg";


interface Props {
    translator?: any
}

class HeaderComponent extends React.Component<Props> {

    state = {
        currentLanguage: false
    }

    changeLanguage() {
        this.setState({currentLanguage : !this.state.currentLanguage})
        if (this.state.currentLanguage) {
            i18next.changeLanguage("es")
        } else {
            i18next.changeLanguage("en")
        }
    }

    render(){

        return(

            <header>

                <div className="container-header">

                    <div className="float-nav" ><label className="iconM" htmlFor="menu-bar" ></label> </div>
                    <div className="float-icon" ><img className="icon" src={log} alt=""/> </div>
                    <input type="checkbox" id="menu-bar"></input>
                    <nav className="menu">
                        <a href="#QueEs">{this.props.translator('LinkTextOne')}</a>
                        <a href="#TuApp">{this.props.translator('LinkTextTwo')}</a>
                        <a href="#Planes">{this.props.translator('LinkTextThree')}</a>
                        <a href="#Contacto">{this.props.translator('LinkTextFour')}</a>
                        <Link  to="/faqs">  FAQ</Link>

                        <div className="header-buttons d-flex align-items-center">
                            <img className="lang-icon" src={lang} alt= "" onClick={() => this.changeLanguage()}/>
                            |
                            <a className="logging-button" href="https://admin.mypopstoreapp.com/login">{this.props.translator('LinkTextEight')}</a>
                            <Link to="/form">
                                <button className="register-button">{this.props.translator('LinkTextNine')}</button>
                            </Link>
                        </div>

                    </nav>

                </div>
            </header>


        );
    }
}

export default HeaderComponent;
