import React from "react";
import {Translation} from 'react-i18next';
import NewHeader from "../theme/formComponents/header/newHeader";
import upLeftCorner from '../assets/images2/Body/upLeftCorner.png';
import downRightCorner from '../assets/images2/Body/downRightCorner.png';
import './Form.scss';
import '../App.scss';
import {Route} from "react-router-dom";
import FormStepZero from "../theme/formComponents/StepZero/FormStepZero";
import FormStepOne from "../theme/formComponents/StepOne/FormStepOne";
import {RegistrationFormService} from "../theme/formComponents/RegistrationForm.service";
import FormVerifyAccount from "../theme/formComponents/VerifyAccount/FormVerifyAccount";
import FormStepTwo from "../theme/formComponents/StepTwo/FormStepTwo";
import FormStepThree from "../theme/formComponents/StepThree/FormStepThree";
import FormSuccessScreen from "../theme/formComponents/SuccessScreen/FormSuccessScreen";


export class Form extends React.Component {

    state = {
        currentStep: "step1"
    };
    translator: any;

    constructor(props: any) {
        super(props);

        window.scroll(0, 0);

    }

    initTranslator() {
    }

    navToStep = (step: number) => {

    }

    render() {
        return (
            <Translation>
                {
                    t => {
                        return <div className="relative">
                            <img className="upLeftCorner" src={upLeftCorner} alt="logo"/>
                            <img className="downRightCorner" src={downRightCorner} alt="logo"/>

                            {window.location.pathname === '/form' ?
                                <NewHeader translator={t} return={false}/>
                                : window.location.pathname === '/form/success' ?
                                    <div className="header-spacer"></div>
                                    :
                                    <NewHeader translator={t} return={true}/>
                            }

                            <Route path="/form" exact render={() => {
                                return <div className="stepsProgressBar step1"></div>
                            }}/>
                            <Route path="/form" exact render={() => {
                                return <FormStepZero translator={t}/>
                            }}/>

                            <Route path="/form/step-one" exact render={() => {
                                return <div className="stepsProgressBar step2"></div>
                            }}/>
                            <Route path="/form/step-one" exact render={() => {
                                return <FormStepOne translator={t} service={RegistrationFormService.getInstance()}/>
                            }}/>

                            <Route path="/form/verify-account" exact render={() => {
                                return <div className="stepsProgressBar step2"></div>
                            }}/>
                            <Route path="/form/verify-account" exact render={() => {
                                return <FormVerifyAccount translator={t}
                                                          service={RegistrationFormService.getInstance()}/>
                            }}/>

                            <Route path="/form/step-two" exact render={() => {
                                return <div className="stepsProgressBar step3"></div>
                            }}/>
                            <Route path="/form/step-two" exact render={() => {
                                return <FormStepTwo translator={t} service={RegistrationFormService.getInstance()}/>
                            }}/>

                            <Route path="/form/step-three" exact render={() => {
                                return <div className="stepsProgressBar step4"></div>
                            }}/>
                            <Route path="/form/step-three" exact render={() => {
                                return <FormStepThree translator={t} service={RegistrationFormService.getInstance()}/>
                            }}/>

                            <Route path="/form/success" exact render={() => {
                                return <FormSuccessScreen translator={t}/>
                            }}/>
                        </div>
                    }
                }
            </Translation>
        );
    }

}

export default Form;