import React from 'react';
import './header.css';
import log from '../../../../src/assets/images2/header/Grupo 12@2x.png'
import cloudBlue from '../../../../src/assets/images2/header/Trazado_9252_1_@2x.png'
import i18next from "i18next";
import lang from "../../../assets/images/header/ico99.svg";

interface Props {
    translator?: any
}

class HeaderComponent extends React.Component <Props> {
    state = {
        currentLanguage: false
    }

    changeLanguage() {
        this.setState({currentLanguage : !this.state.currentLanguage})
        if (this.state.currentLanguage) {
            i18next.changeLanguage("es")
        } else {
            i18next.changeLanguage("en")
        }
    }

    OpenForm() {
        window.open("https://docs.google.com/forms/d/10c5StD5wOCjXcHtFvFGTFkZ6kIg7ygeetO_aigTTmWI/viewform?ts=5f75588b&gxids=7628&edit_requested=true", "_blank")
    }



    render(){

        return(

            <header>

                <div className="headerContainer" >
                    <img className="cloudBlue" src={cloudBlue} alt=""/>

                    <div className="icon-header" ><img className="iconQuest" src={log} alt="MyPopStore"/> </div>

                   <div className="containerNav">

                           <a className="titleHeader"  href="https://mypopstoreapp.com/">{this.props.translator('Link-home')}</a>
                          <img className="lang-icon-header" src={lang} alt="" onClick={() => this.changeLanguage()}/>

                   </div>

                    <a className="nav-button">
                        <button onClick={()=>this.OpenForm()} className="btn-Header">{this.props.translator('Btn-Empy')}</button> </a>



                </div>
            </header>



        );
    }
}
export default HeaderComponent;
